/* tslint:disable */
/* eslint-disable */
/**
 * Melco ControlCenter APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ControlcenterMelcoModelsApiKey,
    ControlcenterMelcoModelsApiKeyFromJSON,
    ControlcenterMelcoModelsApiKeyToJSON,
    ControlcenterMelcoModelsCreateApiKey,
    ControlcenterMelcoModelsCreateApiKeyFromJSON,
    ControlcenterMelcoModelsCreateApiKeyToJSON,
    ControlcenterMelcoModelsError,
    ControlcenterMelcoModelsErrorFromJSON,
    ControlcenterMelcoModelsErrorToJSON,
    ControlcenterMelcoModelsGenericList1MelcoModelsApiKey,
    ControlcenterMelcoModelsGenericList1MelcoModelsApiKeyFromJSON,
    ControlcenterMelcoModelsGenericList1MelcoModelsApiKeyToJSON,
} from '../models';

export interface APIKeysApiApiKeysCreateRequest {
    controlcenterMelcoModelsCreateApiKey?: ControlcenterMelcoModelsCreateApiKey;
}

export interface APIKeysApiApiKeysDeleteRequest {
    id: string;
}

export interface APIKeysApiApiKeysGetListRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    productCode?: string;
    searchText?: string;
}

/**
 * 
 */
export class APIKeysApi extends runtime.BaseAPI {

    /**
     */
    async apiKeysCreateRaw(requestParameters: APIKeysApiApiKeysCreateRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsApiKey>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/apikeys`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlcenterMelcoModelsCreateApiKeyToJSON(requestParameters.controlcenterMelcoModelsCreateApiKey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsApiKeyFromJSON(jsonValue));
    }

    /**
     */
    async apiKeysCreate(requestParameters: APIKeysApiApiKeysCreateRequest): Promise<ControlcenterMelcoModelsApiKey> {
        const response = await this.apiKeysCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiKeysDeleteRaw(requestParameters: APIKeysApiApiKeysDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiKeysDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/apikeys/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiKeysDelete(requestParameters: APIKeysApiApiKeysDeleteRequest): Promise<void> {
        await this.apiKeysDeleteRaw(requestParameters);
    }

    /**
     */
    async apiKeysGetListRaw(requestParameters: APIKeysApiApiKeysGetListRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsGenericList1MelcoModelsApiKey>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.productCode !== undefined) {
            queryParameters['product_code'] = requestParameters.productCode;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/apikeys`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsGenericList1MelcoModelsApiKeyFromJSON(jsonValue));
    }

    /**
     */
    async apiKeysGetList(requestParameters: APIKeysApiApiKeysGetListRequest): Promise<ControlcenterMelcoModelsGenericList1MelcoModelsApiKey> {
        const response = await this.apiKeysGetListRaw(requestParameters);
        return await response.value();
    }

}
