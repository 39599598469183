/* tslint:disable */
/* eslint-disable */
/**
 * Melco ControlCenter APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlcenterMelcoControlCenterModelsForwardLinkListItem
 */
export interface ControlcenterMelcoControlCenterModelsForwardLinkListItem {
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoControlCenterModelsForwardLinkListItem
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoControlCenterModelsForwardLinkListItem
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoControlCenterModelsForwardLinkListItem
     */
    target_url?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlcenterMelcoControlCenterModelsForwardLinkListItem
     */
    opened?: number;
    /**
     * 
     * @type {Date}
     * @memberof ControlcenterMelcoControlCenterModelsForwardLinkListItem
     */
    last_opened?: Date | null;
}

export function ControlcenterMelcoControlCenterModelsForwardLinkListItemFromJSON(json: any): ControlcenterMelcoControlCenterModelsForwardLinkListItem {
    return ControlcenterMelcoControlCenterModelsForwardLinkListItemFromJSONTyped(json, false);
}

export function ControlcenterMelcoControlCenterModelsForwardLinkListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlcenterMelcoControlCenterModelsForwardLinkListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'target_url': !exists(json, 'target_url') ? undefined : json['target_url'],
        'opened': !exists(json, 'opened') ? undefined : json['opened'],
        'last_opened': !exists(json, 'last_opened') ? undefined : (json['last_opened'] === null ? null : new Date(json['last_opened'])),
    };
}

export function ControlcenterMelcoControlCenterModelsForwardLinkListItemToJSON(value?: ControlcenterMelcoControlCenterModelsForwardLinkListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'key': value.key,
        'target_url': value.target_url,
        'opened': value.opened,
        'last_opened': value.last_opened === undefined ? undefined : (value.last_opened === null ? null : value.last_opened.toISOString()),
    };
}


