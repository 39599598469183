/* tslint:disable */
/* eslint-disable */
/**
 * Melco Identity APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IdentityMelcoControlCenterModelsControlCenterAppSetting,
    IdentityMelcoControlCenterModelsControlCenterAppSettingFromJSON,
    IdentityMelcoControlCenterModelsControlCenterAppSettingToJSON,
    IdentityMelcoModelsDefaultAppSetting,
    IdentityMelcoModelsDefaultAppSettingFromJSON,
    IdentityMelcoModelsDefaultAppSettingToJSON,
    IdentityMelcoModelsError,
    IdentityMelcoModelsErrorFromJSON,
    IdentityMelcoModelsErrorToJSON,
} from '../models';

/**
 * 
 */
export class SystemManagementApi extends runtime.BaseAPI {

    /**
     */
    async productSettingsGetApiServiceAppSettingRaw(): Promise<runtime.ApiResponse<IdentityMelcoModelsDefaultAppSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/api_service/settings/app`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsDefaultAppSettingFromJSON(jsonValue));
    }

    /**
     */
    async productSettingsGetApiServiceAppSetting(): Promise<IdentityMelcoModelsDefaultAppSetting> {
        const response = await this.productSettingsGetApiServiceAppSettingRaw();
        return await response.value();
    }

    /**
     */
    async productSettingsGetControlCenterAppSettingRaw(): Promise<runtime.ApiResponse<IdentityMelcoControlCenterModelsControlCenterAppSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/control_center/settings/app`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoControlCenterModelsControlCenterAppSettingFromJSON(jsonValue));
    }

    /**
     */
    async productSettingsGetControlCenterAppSetting(): Promise<IdentityMelcoControlCenterModelsControlCenterAppSetting> {
        const response = await this.productSettingsGetControlCenterAppSettingRaw();
        return await response.value();
    }

    /**
     */
    async productSettingsGetFusionAppSettingRaw(): Promise<runtime.ApiResponse<IdentityMelcoModelsDefaultAppSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/fusion/settings/app`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsDefaultAppSettingFromJSON(jsonValue));
    }

    /**
     */
    async productSettingsGetFusionAppSetting(): Promise<IdentityMelcoModelsDefaultAppSetting> {
        const response = await this.productSettingsGetFusionAppSettingRaw();
        return await response.value();
    }

}
