/* tslint:disable */
/* eslint-disable */
/**
 * Melco ControlCenter APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ControlcenterMelcoModelsError,
    ControlcenterMelcoModelsErrorFromJSON,
    ControlcenterMelcoModelsErrorToJSON,
    ControlcenterMelcoModelsGenericList1MelcoModelsLocaleModule,
    ControlcenterMelcoModelsGenericList1MelcoModelsLocaleModuleFromJSON,
    ControlcenterMelcoModelsGenericList1MelcoModelsLocaleModuleToJSON,
    ControlcenterMelcoModelsGenericList1MelcoModelsLocaleString,
    ControlcenterMelcoModelsGenericList1MelcoModelsLocaleStringFromJSON,
    ControlcenterMelcoModelsGenericList1MelcoModelsLocaleStringToJSON,
    ControlcenterMelcoModelsGenericList1MelcoModelsLocaleStringListItem,
    ControlcenterMelcoModelsGenericList1MelcoModelsLocaleStringListItemFromJSON,
    ControlcenterMelcoModelsGenericList1MelcoModelsLocaleStringListItemToJSON,
    ControlcenterMelcoModelsLocaleString,
    ControlcenterMelcoModelsLocaleStringFromJSON,
    ControlcenterMelcoModelsLocaleStringToJSON,
} from '../models';

export interface LocalizationApiLocalizationEditRequest {
    id: string;
    moduleCode: string;
    controlcenterMelcoModelsLocaleString?: ControlcenterMelcoModelsLocaleString;
}

export interface LocalizationApiLocalizationExportRequest {
    moduleCode: string;
    cultureCode?: string;
}

export interface LocalizationApiLocalizationGetRequest {
    id: string;
    moduleCode: string;
}

export interface LocalizationApiLocalizationGetListRequest {
    moduleCode: string;
    cultureCode?: string;
    searchText?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
}

export interface LocalizationApiLocalizationImportRequest {
    moduleCode: string;
    cultureCode?: string;
    file?: Blob;
}

/**
 * 
 */
export class LocalizationApi extends runtime.BaseAPI {

    /**
     */
    async localizationEditRaw(requestParameters: LocalizationApiLocalizationEditRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsLocaleString>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling localizationEdit.');
        }

        if (requestParameters.moduleCode === null || requestParameters.moduleCode === undefined) {
            throw new runtime.RequiredError('moduleCode','Required parameter requestParameters.moduleCode was null or undefined when calling localizationEdit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/localization/{module_code}/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"module_code"}}`, encodeURIComponent(String(requestParameters.moduleCode))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ControlcenterMelcoModelsLocaleStringToJSON(requestParameters.controlcenterMelcoModelsLocaleString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsLocaleStringFromJSON(jsonValue));
    }

    /**
     */
    async localizationEdit(requestParameters: LocalizationApiLocalizationEditRequest): Promise<ControlcenterMelcoModelsLocaleString> {
        const response = await this.localizationEditRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async localizationExportRaw(requestParameters: LocalizationApiLocalizationExportRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.moduleCode === null || requestParameters.moduleCode === undefined) {
            throw new runtime.RequiredError('moduleCode','Required parameter requestParameters.moduleCode was null or undefined when calling localizationExport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cultureCode !== undefined) {
            queryParameters['culture_code'] = requestParameters.cultureCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/localization/{module_code}/export`.replace(`{${"module_code"}}`, encodeURIComponent(String(requestParameters.moduleCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async localizationExport(requestParameters: LocalizationApiLocalizationExportRequest): Promise<Blob> {
        const response = await this.localizationExportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async localizationGetRaw(requestParameters: LocalizationApiLocalizationGetRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsLocaleString>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling localizationGet.');
        }

        if (requestParameters.moduleCode === null || requestParameters.moduleCode === undefined) {
            throw new runtime.RequiredError('moduleCode','Required parameter requestParameters.moduleCode was null or undefined when calling localizationGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/localization/{module_code}/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"module_code"}}`, encodeURIComponent(String(requestParameters.moduleCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsLocaleStringFromJSON(jsonValue));
    }

    /**
     */
    async localizationGet(requestParameters: LocalizationApiLocalizationGetRequest): Promise<ControlcenterMelcoModelsLocaleString> {
        const response = await this.localizationGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async localizationGetListRaw(requestParameters: LocalizationApiLocalizationGetListRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsGenericList1MelcoModelsLocaleStringListItem>> {
        if (requestParameters.moduleCode === null || requestParameters.moduleCode === undefined) {
            throw new runtime.RequiredError('moduleCode','Required parameter requestParameters.moduleCode was null or undefined when calling localizationGetList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cultureCode !== undefined) {
            queryParameters['culture_code'] = requestParameters.cultureCode;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/localization/{module_code}`.replace(`{${"module_code"}}`, encodeURIComponent(String(requestParameters.moduleCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsGenericList1MelcoModelsLocaleStringListItemFromJSON(jsonValue));
    }

    /**
     */
    async localizationGetList(requestParameters: LocalizationApiLocalizationGetListRequest): Promise<ControlcenterMelcoModelsGenericList1MelcoModelsLocaleStringListItem> {
        const response = await this.localizationGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async localizationGetModuleListRaw(): Promise<runtime.ApiResponse<ControlcenterMelcoModelsGenericList1MelcoModelsLocaleModule>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/localization/modules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsGenericList1MelcoModelsLocaleModuleFromJSON(jsonValue));
    }

    /**
     */
    async localizationGetModuleList(): Promise<ControlcenterMelcoModelsGenericList1MelcoModelsLocaleModule> {
        const response = await this.localizationGetModuleListRaw();
        return await response.value();
    }

    /**
     */
    async localizationImportRaw(requestParameters: LocalizationApiLocalizationImportRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsGenericList1MelcoModelsLocaleString>> {
        if (requestParameters.moduleCode === null || requestParameters.moduleCode === undefined) {
            throw new runtime.RequiredError('moduleCode','Required parameter requestParameters.moduleCode was null or undefined when calling localizationImport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cultureCode !== undefined) {
            queryParameters['culture_code'] = requestParameters.cultureCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/manage/localization/{module_code}/import`.replace(`{${"module_code"}}`, encodeURIComponent(String(requestParameters.moduleCode))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsGenericList1MelcoModelsLocaleStringFromJSON(jsonValue));
    }

    /**
     */
    async localizationImport(requestParameters: LocalizationApiLocalizationImportRequest): Promise<ControlcenterMelcoModelsGenericList1MelcoModelsLocaleString> {
        const response = await this.localizationImportRaw(requestParameters);
        return await response.value();
    }

}
