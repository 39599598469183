/* tslint:disable */
/* eslint-disable */
/**
 * Melco ControlCenter APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlcenterMelcoModelsEmail
 */
export interface ControlcenterMelcoModelsEmail {
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsEmail
     */
    html?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsEmail
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsEmail
     */
    product_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsEmail
     */
    email_address?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ControlcenterMelcoModelsEmail
     */
    time_stamp?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ControlcenterMelcoModelsEmail
     */
    is_email_sent?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ControlcenterMelcoModelsEmail
     */
    total?: number;
}

export function ControlcenterMelcoModelsEmailFromJSON(json: any): ControlcenterMelcoModelsEmail {
    return ControlcenterMelcoModelsEmailFromJSONTyped(json, false);
}

export function ControlcenterMelcoModelsEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlcenterMelcoModelsEmail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'html': !exists(json, 'html') ? undefined : json['html'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
        'email_address': !exists(json, 'email_address') ? undefined : json['email_address'],
        'time_stamp': !exists(json, 'time_stamp') ? undefined : (new Date(json['time_stamp'])),
        'is_email_sent': !exists(json, 'is_email_sent') ? undefined : json['is_email_sent'],
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function ControlcenterMelcoModelsEmailToJSON(value?: ControlcenterMelcoModelsEmail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'html': value.html,
        'id': value.id,
        'product_code': value.product_code,
        'email_address': value.email_address,
        'time_stamp': value.time_stamp === undefined ? undefined : (value.time_stamp.toISOString()),
        'is_email_sent': value.is_email_sent,
        'total': value.total,
    };
}


