/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoModelsCreateStatusRecord,
    MelcoModelsCreateStatusRecordFromJSON,
    MelcoModelsCreateStatusRecordToJSON,
    MelcoModelsError,
    MelcoModelsErrorFromJSON,
    MelcoModelsErrorToJSON,
    MelcoModelsGenericList1MelcoAnalyticsModelsAvailabilityAppListItem,
    MelcoModelsGenericList1MelcoAnalyticsModelsAvailabilityAppListItemFromJSON,
    MelcoModelsGenericList1MelcoAnalyticsModelsAvailabilityAppListItemToJSON,
    MelcoModelsGenericList1MelcoAnalyticsModelsAvailabilityStatusListItem,
    MelcoModelsGenericList1MelcoAnalyticsModelsAvailabilityStatusListItemFromJSON,
    MelcoModelsGenericList1MelcoAnalyticsModelsAvailabilityStatusListItemToJSON,
    MelcoModelsGenericList1MelcoModelsDesignDownloadDailySummary,
    MelcoModelsGenericList1MelcoModelsDesignDownloadDailySummaryFromJSON,
    MelcoModelsGenericList1MelcoModelsDesignDownloadDailySummaryToJSON,
    MelcoModelsGenericList1MelcoModelsLogListItem,
    MelcoModelsGenericList1MelcoModelsLogListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsLogListItemToJSON,
    MelcoModelsGenericList1MelcoModelsSessionDailySummary,
    MelcoModelsGenericList1MelcoModelsSessionDailySummaryFromJSON,
    MelcoModelsGenericList1MelcoModelsSessionDailySummaryToJSON,
    MelcoModelsLogData,
    MelcoModelsLogDataFromJSON,
    MelcoModelsLogDataToJSON,
    MelcoModelsLogUpload,
    MelcoModelsLogUploadFromJSON,
    MelcoModelsLogUploadToJSON,
} from '../models';

export interface ProductAnalyticsApiDiagnosticsLogFilesDownloadRequest {
    id: string;
}

export interface ProductAnalyticsApiDiagnosticsLogFilesGetListRequest {
    searchText?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
    productList?: string;
}

export interface ProductAnalyticsApiDiagnosticsLogFilesUploadRequest {
    melcoModelsLogUpload?: MelcoModelsLogUpload;
}

export interface ProductAnalyticsApiProductAnalyticsAdminDesignSummaryRequest {
    productCode: string;
    startTimestamp?: Date;
    endTimestamp?: Date;
}

export interface ProductAnalyticsApiProductAnalyticsAdminExportDesignSummaryRequest {
    productCode: string;
    startTimestamp?: Date;
    endTimestamp?: Date;
    format?: string;
}

export interface ProductAnalyticsApiProductAnalyticsDailyDesignSummaryRequest {
    productCode: string;
    startTimestamp?: Date;
    endTimestamp?: Date;
}

export interface ProductAnalyticsApiProductAnalyticsDailySessionSummaryRequest {
    productCode: string;
    startTimestamp?: Date;
    endTimestamp?: Date;
}

export interface ProductAnalyticsApiProductAnalyticsExportDesignSummaryRequest {
    productCode: string;
    startTimestamp?: Date;
    endTimestamp?: Date;
    format?: string;
}

export interface ProductAnalyticsApiStatusServiceGetDailyListRequest {
    appList?: string;
}

export interface ProductAnalyticsApiStatusServiceGetHourlyListRequest {
    appList?: string;
}

export interface ProductAnalyticsApiStatusServiceGetListRequest {
    includeInternalApps?: boolean;
}

export interface ProductAnalyticsApiStatusServiceGetMonthlyListRequest {
    appList?: string;
}

export interface ProductAnalyticsApiStatusServiceReportStatusRequest {
    melcoModelsCreateStatusRecord?: MelcoModelsCreateStatusRecord;
}

/**
 * 
 */
export class ProductAnalyticsApi extends runtime.BaseAPI {

    /**
     * Download log file
     */
    async diagnosticsLogFilesDownloadRaw(requestParameters: ProductAnalyticsApiDiagnosticsLogFilesDownloadRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling diagnosticsLogFilesDownload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/analytics/diagnostics/log_files/{Id}/download`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download log file
     */
    async diagnosticsLogFilesDownload(requestParameters: ProductAnalyticsApiDiagnosticsLogFilesDownloadRequest): Promise<Blob> {
        const response = await this.diagnosticsLogFilesDownloadRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get log files
     */
    async diagnosticsLogFilesGetListRaw(requestParameters: ProductAnalyticsApiDiagnosticsLogFilesGetListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsLogListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.productList !== undefined) {
            queryParameters['product_list'] = requestParameters.productList;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/analytics/diagnostics/log_files`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsLogListItemFromJSON(jsonValue));
    }

    /**
     * Get log files
     */
    async diagnosticsLogFilesGetList(requestParameters: ProductAnalyticsApiDiagnosticsLogFilesGetListRequest): Promise<MelcoModelsGenericList1MelcoModelsLogListItem> {
        const response = await this.diagnosticsLogFilesGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Upload log file
     */
    async diagnosticsLogFilesUploadRaw(requestParameters: ProductAnalyticsApiDiagnosticsLogFilesUploadRequest): Promise<runtime.ApiResponse<MelcoModelsLogData>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/analytics/diagnostics/log_files`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsLogUploadToJSON(requestParameters.melcoModelsLogUpload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsLogDataFromJSON(jsonValue));
    }

    /**
     * Upload log file
     */
    async diagnosticsLogFilesUpload(requestParameters: ProductAnalyticsApiDiagnosticsLogFilesUploadRequest): Promise<MelcoModelsLogData> {
        const response = await this.diagnosticsLogFilesUploadRaw(requestParameters);
        return await response.value();
    }

    /**
     * get daily design download summary
     * get daily design download summary for all users
     */
    async productAnalyticsAdminDesignSummaryRaw(requestParameters: ProductAnalyticsApiProductAnalyticsAdminDesignSummaryRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsDesignDownloadDailySummary>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling productAnalyticsAdminDesignSummary.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startTimestamp !== undefined) {
            queryParameters['start_timestamp'] = (requestParameters.startTimestamp as any).toISOString();
        }

        if (requestParameters.endTimestamp !== undefined) {
            queryParameters['end_timestamp'] = (requestParameters.endTimestamp as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/analytics/products/{product_code}/designs/dailysummary`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsDesignDownloadDailySummaryFromJSON(jsonValue));
    }

    /**
     * get daily design download summary
     * get daily design download summary for all users
     */
    async productAnalyticsAdminDesignSummary(requestParameters: ProductAnalyticsApiProductAnalyticsAdminDesignSummaryRequest): Promise<MelcoModelsGenericList1MelcoModelsDesignDownloadDailySummary> {
        const response = await this.productAnalyticsAdminDesignSummaryRaw(requestParameters);
        return await response.value();
    }

    /**
     * export csv
     * export design download details(csv) for all users
     */
    async productAnalyticsAdminExportDesignSummaryRaw(requestParameters: ProductAnalyticsApiProductAnalyticsAdminExportDesignSummaryRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling productAnalyticsAdminExportDesignSummary.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startTimestamp !== undefined) {
            queryParameters['start_timestamp'] = (requestParameters.startTimestamp as any).toISOString();
        }

        if (requestParameters.endTimestamp !== undefined) {
            queryParameters['end_timestamp'] = (requestParameters.endTimestamp as any).toISOString();
        }

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/analytics/products/{product_code}/designs/dailysummary/export`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * export csv
     * export design download details(csv) for all users
     */
    async productAnalyticsAdminExportDesignSummary(requestParameters: ProductAnalyticsApiProductAnalyticsAdminExportDesignSummaryRequest): Promise<Blob> {
        const response = await this.productAnalyticsAdminExportDesignSummaryRaw(requestParameters);
        return await response.value();
    }

    /**
     * get daily design download summary
     * get daily design download summary
     */
    async productAnalyticsDailyDesignSummaryRaw(requestParameters: ProductAnalyticsApiProductAnalyticsDailyDesignSummaryRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsDesignDownloadDailySummary>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling productAnalyticsDailyDesignSummary.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startTimestamp !== undefined) {
            queryParameters['start_timestamp'] = (requestParameters.startTimestamp as any).toISOString();
        }

        if (requestParameters.endTimestamp !== undefined) {
            queryParameters['end_timestamp'] = (requestParameters.endTimestamp as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/analytics/products/{product_code}/designs/dailysummary`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsDesignDownloadDailySummaryFromJSON(jsonValue));
    }

    /**
     * get daily design download summary
     * get daily design download summary
     */
    async productAnalyticsDailyDesignSummary(requestParameters: ProductAnalyticsApiProductAnalyticsDailyDesignSummaryRequest): Promise<MelcoModelsGenericList1MelcoModelsDesignDownloadDailySummary> {
        const response = await this.productAnalyticsDailyDesignSummaryRaw(requestParameters);
        return await response.value();
    }

    /**
     * get daily sessions summary
     * get sessions summary
     */
    async productAnalyticsDailySessionSummaryRaw(requestParameters: ProductAnalyticsApiProductAnalyticsDailySessionSummaryRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsSessionDailySummary>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling productAnalyticsDailySessionSummary.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startTimestamp !== undefined) {
            queryParameters['start_timestamp'] = (requestParameters.startTimestamp as any).toISOString();
        }

        if (requestParameters.endTimestamp !== undefined) {
            queryParameters['end_timestamp'] = (requestParameters.endTimestamp as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/analytics/products/{product_code}/sessions/dailysummary`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsSessionDailySummaryFromJSON(jsonValue));
    }

    /**
     * get daily sessions summary
     * get sessions summary
     */
    async productAnalyticsDailySessionSummary(requestParameters: ProductAnalyticsApiProductAnalyticsDailySessionSummaryRequest): Promise<MelcoModelsGenericList1MelcoModelsSessionDailySummary> {
        const response = await this.productAnalyticsDailySessionSummaryRaw(requestParameters);
        return await response.value();
    }

    /**
     * export csv
     * export design download details(csv)
     */
    async productAnalyticsExportDesignSummaryRaw(requestParameters: ProductAnalyticsApiProductAnalyticsExportDesignSummaryRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling productAnalyticsExportDesignSummary.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startTimestamp !== undefined) {
            queryParameters['start_timestamp'] = (requestParameters.startTimestamp as any).toISOString();
        }

        if (requestParameters.endTimestamp !== undefined) {
            queryParameters['end_timestamp'] = (requestParameters.endTimestamp as any).toISOString();
        }

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/analytics/products/{product_code}/designs/dailysummary/export`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * export csv
     * export design download details(csv)
     */
    async productAnalyticsExportDesignSummary(requestParameters: ProductAnalyticsApiProductAnalyticsExportDesignSummaryRequest): Promise<Blob> {
        const response = await this.productAnalyticsExportDesignSummaryRaw(requestParameters);
        return await response.value();
    }

    /**
     * get list of records from daily status data
     * get list of daily status data
     */
    async statusServiceGetDailyListRaw(requestParameters: ProductAnalyticsApiStatusServiceGetDailyListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoAnalyticsModelsAvailabilityStatusListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.appList !== undefined) {
            queryParameters['app_list'] = requestParameters.appList;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/analytics/status/daily`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoAnalyticsModelsAvailabilityStatusListItemFromJSON(jsonValue));
    }

    /**
     * get list of records from daily status data
     * get list of daily status data
     */
    async statusServiceGetDailyList(requestParameters: ProductAnalyticsApiStatusServiceGetDailyListRequest): Promise<MelcoModelsGenericList1MelcoAnalyticsModelsAvailabilityStatusListItem> {
        const response = await this.statusServiceGetDailyListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get list of records from hourly status data
     * get list of hourly status data
     */
    async statusServiceGetHourlyListRaw(requestParameters: ProductAnalyticsApiStatusServiceGetHourlyListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoAnalyticsModelsAvailabilityStatusListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.appList !== undefined) {
            queryParameters['app_list'] = requestParameters.appList;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/analytics/status/hourly`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoAnalyticsModelsAvailabilityStatusListItemFromJSON(jsonValue));
    }

    /**
     * get list of records from hourly status data
     * get list of hourly status data
     */
    async statusServiceGetHourlyList(requestParameters: ProductAnalyticsApiStatusServiceGetHourlyListRequest): Promise<MelcoModelsGenericList1MelcoAnalyticsModelsAvailabilityStatusListItem> {
        const response = await this.statusServiceGetHourlyListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get list of application from master table
     * get list of apps
     */
    async statusServiceGetListRaw(requestParameters: ProductAnalyticsApiStatusServiceGetListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoAnalyticsModelsAvailabilityAppListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includeInternalApps !== undefined) {
            queryParameters['include_internal_apps'] = requestParameters.includeInternalApps;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/analytics/status/apps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoAnalyticsModelsAvailabilityAppListItemFromJSON(jsonValue));
    }

    /**
     * get list of application from master table
     * get list of apps
     */
    async statusServiceGetList(requestParameters: ProductAnalyticsApiStatusServiceGetListRequest): Promise<MelcoModelsGenericList1MelcoAnalyticsModelsAvailabilityAppListItem> {
        const response = await this.statusServiceGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get list of records for monthly
     * get list of monthly status data
     */
    async statusServiceGetMonthlyListRaw(requestParameters: ProductAnalyticsApiStatusServiceGetMonthlyListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoAnalyticsModelsAvailabilityStatusListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.appList !== undefined) {
            queryParameters['app_list'] = requestParameters.appList;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/analytics/status/monthly`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoAnalyticsModelsAvailabilityStatusListItemFromJSON(jsonValue));
    }

    /**
     * get list of records for monthly
     * get list of monthly status data
     */
    async statusServiceGetMonthlyList(requestParameters: ProductAnalyticsApiStatusServiceGetMonthlyListRequest): Promise<MelcoModelsGenericList1MelcoAnalyticsModelsAvailabilityStatusListItem> {
        const response = await this.statusServiceGetMonthlyListRaw(requestParameters);
        return await response.value();
    }

    /**
     * create new status record
     */
    async statusServiceReportStatusRaw(requestParameters: ProductAnalyticsApiStatusServiceReportStatusRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/analytics/status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsCreateStatusRecordToJSON(requestParameters.melcoModelsCreateStatusRecord),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * create new status record
     */
    async statusServiceReportStatus(requestParameters: ProductAnalyticsApiStatusServiceReportStatusRequest): Promise<void> {
        await this.statusServiceReportStatusRaw(requestParameters);
    }

}
