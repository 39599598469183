/* tslint:disable */
/* eslint-disable */
/**
 * Melco ControlCenter APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ControlcenterMelcoModelsCreateHoop,
    ControlcenterMelcoModelsCreateHoopFromJSON,
    ControlcenterMelcoModelsCreateHoopToJSON,
    ControlcenterMelcoModelsError,
    ControlcenterMelcoModelsErrorFromJSON,
    ControlcenterMelcoModelsErrorToJSON,
    ControlcenterMelcoModelsGenericList1MelcoModelsHoopListItem,
    ControlcenterMelcoModelsGenericList1MelcoModelsHoopListItemFromJSON,
    ControlcenterMelcoModelsGenericList1MelcoModelsHoopListItemToJSON,
    ControlcenterMelcoModelsHoop,
    ControlcenterMelcoModelsHoopFromJSON,
    ControlcenterMelcoModelsHoopToJSON,
} from '../models';

export interface HoopsApiHoopsCreateDraftRequest {
    controlcenterMelcoModelsCreateHoop?: ControlcenterMelcoModelsCreateHoop;
}

export interface HoopsApiHoopsDeleteRequest {
    id: string;
}

export interface HoopsApiHoopsDuplicateDraftRequest {
    id: string;
}

export interface HoopsApiHoopsGetDefRequest {
    id: string;
    publishState?: string;
}

export interface HoopsApiHoopsPublishRequest {
    id: string;
}

export interface HoopsApiHoopsRevertRequest {
    id: string;
}

export interface HoopsApiHoopsSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    publishState?: string;
    searchText?: string;
    name?: string;
    productList?: string;
    machineList?: string;
    categoryList?: string;
    version?: string;
}

export interface HoopsApiHoopsUpdateRequest {
    id: string;
    controlcenterMelcoModelsCreateHoop?: ControlcenterMelcoModelsCreateHoop;
}

export interface HoopsApiHoopsUploadRequest {
    id: string;
    file?: Blob;
}

/**
 * 
 */
export class HoopsApi extends runtime.BaseAPI {

    /**
     */
    async hoopsCreateDraftRaw(requestParameters: HoopsApiHoopsCreateDraftRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsHoop>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/hoops/draft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlcenterMelcoModelsCreateHoopToJSON(requestParameters.controlcenterMelcoModelsCreateHoop),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsHoopFromJSON(jsonValue));
    }

    /**
     */
    async hoopsCreateDraft(requestParameters: HoopsApiHoopsCreateDraftRequest): Promise<ControlcenterMelcoModelsHoop> {
        const response = await this.hoopsCreateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async hoopsDeleteRaw(requestParameters: HoopsApiHoopsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling hoopsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/hoops/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async hoopsDelete(requestParameters: HoopsApiHoopsDeleteRequest): Promise<void> {
        await this.hoopsDeleteRaw(requestParameters);
    }

    /**
     */
    async hoopsDuplicateDraftRaw(requestParameters: HoopsApiHoopsDuplicateDraftRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsHoop>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling hoopsDuplicateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/hoops/{Id}/duplicate/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsHoopFromJSON(jsonValue));
    }

    /**
     */
    async hoopsDuplicateDraft(requestParameters: HoopsApiHoopsDuplicateDraftRequest): Promise<ControlcenterMelcoModelsHoop> {
        const response = await this.hoopsDuplicateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async hoopsGetDefRaw(requestParameters: HoopsApiHoopsGetDefRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsHoop>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling hoopsGetDef.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/hoops/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsHoopFromJSON(jsonValue));
    }

    /**
     */
    async hoopsGetDef(requestParameters: HoopsApiHoopsGetDefRequest): Promise<ControlcenterMelcoModelsHoop> {
        const response = await this.hoopsGetDefRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async hoopsPublishRaw(requestParameters: HoopsApiHoopsPublishRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsHoop>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling hoopsPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/hoops/{Id}/publish`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsHoopFromJSON(jsonValue));
    }

    /**
     */
    async hoopsPublish(requestParameters: HoopsApiHoopsPublishRequest): Promise<ControlcenterMelcoModelsHoop> {
        const response = await this.hoopsPublishRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async hoopsRevertRaw(requestParameters: HoopsApiHoopsRevertRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling hoopsRevert.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/hoops/{Id}/revert`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async hoopsRevert(requestParameters: HoopsApiHoopsRevertRequest): Promise<void> {
        await this.hoopsRevertRaw(requestParameters);
    }

    /**
     */
    async hoopsSearchRaw(requestParameters: HoopsApiHoopsSearchRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsGenericList1MelcoModelsHoopListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.productList !== undefined) {
            queryParameters['product_list'] = requestParameters.productList;
        }

        if (requestParameters.machineList !== undefined) {
            queryParameters['machine_list'] = requestParameters.machineList;
        }

        if (requestParameters.categoryList !== undefined) {
            queryParameters['category_list'] = requestParameters.categoryList;
        }

        if (requestParameters.version !== undefined) {
            queryParameters['version'] = requestParameters.version;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/hoops`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsGenericList1MelcoModelsHoopListItemFromJSON(jsonValue));
    }

    /**
     */
    async hoopsSearch(requestParameters: HoopsApiHoopsSearchRequest): Promise<ControlcenterMelcoModelsGenericList1MelcoModelsHoopListItem> {
        const response = await this.hoopsSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async hoopsUpdateRaw(requestParameters: HoopsApiHoopsUpdateRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsHoop>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling hoopsUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/hoops/{Id}/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ControlcenterMelcoModelsCreateHoopToJSON(requestParameters.controlcenterMelcoModelsCreateHoop),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsHoopFromJSON(jsonValue));
    }

    /**
     */
    async hoopsUpdate(requestParameters: HoopsApiHoopsUpdateRequest): Promise<ControlcenterMelcoModelsHoop> {
        const response = await this.hoopsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async hoopsUploadRaw(requestParameters: HoopsApiHoopsUploadRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsHoop>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling hoopsUpload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/manage/hoops/{Id}/draft/upload`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsHoopFromJSON(jsonValue));
    }

    /**
     */
    async hoopsUpload(requestParameters: HoopsApiHoopsUploadRequest): Promise<ControlcenterMelcoModelsHoop> {
        const response = await this.hoopsUploadRaw(requestParameters);
        return await response.value();
    }

}
