/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoModelsError,
    MelcoModelsErrorFromJSON,
    MelcoModelsErrorToJSON,
    MelcoModelsGenericList1MelcoModelsKeyValue,
    MelcoModelsGenericList1MelcoModelsKeyValueFromJSON,
    MelcoModelsGenericList1MelcoModelsKeyValueToJSON,
} from '../models';

export interface SystemDefaultsApiDefaultsGetDocumentRequest {
    id: string;
}

/**
 * 
 */
export class SystemDefaultsApi extends runtime.BaseAPI {

    /**
     * get document (supported types: terms)
     * get document
     */
    async defaultsGetDocumentRaw(requestParameters: SystemDefaultsApiDefaultsGetDocumentRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsKeyValue>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling defaultsGetDocument.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/documents/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsKeyValueFromJSON(jsonValue));
    }

    /**
     * get document (supported types: terms)
     * get document
     */
    async defaultsGetDocument(requestParameters: SystemDefaultsApiDefaultsGetDocumentRequest): Promise<MelcoModelsGenericList1MelcoModelsKeyValue> {
        const response = await this.defaultsGetDocumentRaw(requestParameters);
        return await response.value();
    }

}
