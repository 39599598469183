/* tslint:disable */
/* eslint-disable */
/**
 * Melco APIService APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiserviceMelcoModelsDesignElementColor
 */
export interface ApiserviceMelcoModelsDesignElementColor {
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsDesignElementColor
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsDesignElementColor
     */
    manufacturer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsDesignElementColor
     */
    product_line?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsDesignElementColor
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsDesignElementColor
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsDesignElementColor
     */
    rgb?: string | null;
}

export function ApiserviceMelcoModelsDesignElementColorFromJSON(json: any): ApiserviceMelcoModelsDesignElementColor {
    return ApiserviceMelcoModelsDesignElementColorFromJSONTyped(json, false);
}

export function ApiserviceMelcoModelsDesignElementColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiserviceMelcoModelsDesignElementColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'product_line': !exists(json, 'product_line') ? undefined : json['product_line'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'rgb': !exists(json, 'rgb') ? undefined : json['rgb'],
    };
}

export function ApiserviceMelcoModelsDesignElementColorToJSON(value?: ApiserviceMelcoModelsDesignElementColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'manufacturer': value.manufacturer,
        'product_line': value.product_line,
        'name': value.name,
        'code': value.code,
        'rgb': value.rgb,
    };
}


