/* tslint:disable */
/* eslint-disable */
/**
 * Melco Identity APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IdentityMelcoModelsSelectListItem,
    IdentityMelcoModelsSelectListItemFromJSON,
    IdentityMelcoModelsSelectListItemFromJSONTyped,
    IdentityMelcoModelsSelectListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface IdentityMelcoModelsDefaultAppSetting
 */
export interface IdentityMelcoModelsDefaultAppSetting {
    /**
     * 
     * @type {Array<IdentityMelcoModelsSelectListItem>}
     * @memberof IdentityMelcoModelsDefaultAppSetting
     */
    measurements?: Array<IdentityMelcoModelsSelectListItem> | null;
    /**
     * 
     * @type {Array<IdentityMelcoModelsSelectListItem>}
     * @memberof IdentityMelcoModelsDefaultAppSetting
     */
    emb_formats?: Array<IdentityMelcoModelsSelectListItem> | null;
    /**
     * 
     * @type {number}
     * @memberof IdentityMelcoModelsDefaultAppSetting
     */
    max_canvas_size?: number;
    /**
     * 
     * @type {number}
     * @memberof IdentityMelcoModelsDefaultAppSetting
     */
    min_image_size?: number;
}

export function IdentityMelcoModelsDefaultAppSettingFromJSON(json: any): IdentityMelcoModelsDefaultAppSetting {
    return IdentityMelcoModelsDefaultAppSettingFromJSONTyped(json, false);
}

export function IdentityMelcoModelsDefaultAppSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentityMelcoModelsDefaultAppSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'measurements': !exists(json, 'measurements') ? undefined : (json['measurements'] === null ? null : (json['measurements'] as Array<any>).map(IdentityMelcoModelsSelectListItemFromJSON)),
        'emb_formats': !exists(json, 'emb_formats') ? undefined : (json['emb_formats'] === null ? null : (json['emb_formats'] as Array<any>).map(IdentityMelcoModelsSelectListItemFromJSON)),
        'max_canvas_size': !exists(json, 'max_canvas_size') ? undefined : json['max_canvas_size'],
        'min_image_size': !exists(json, 'min_image_size') ? undefined : json['min_image_size'],
    };
}

export function IdentityMelcoModelsDefaultAppSettingToJSON(value?: IdentityMelcoModelsDefaultAppSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'measurements': value.measurements === undefined ? undefined : (value.measurements === null ? null : (value.measurements as Array<any>).map(IdentityMelcoModelsSelectListItemToJSON)),
        'emb_formats': value.emb_formats === undefined ? undefined : (value.emb_formats === null ? null : (value.emb_formats as Array<any>).map(IdentityMelcoModelsSelectListItemToJSON)),
        'max_canvas_size': value.max_canvas_size,
        'min_image_size': value.min_image_size,
    };
}


