/* tslint:disable */
/* eslint-disable */
/**
 * Melco Identity APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IdentityMelcoModelsCurrency
 */
export interface IdentityMelcoModelsCurrency {
    /**
     * 
     * @type {string}
     * @memberof IdentityMelcoModelsCurrency
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityMelcoModelsCurrency
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityMelcoModelsCurrency
     */
    symbol?: string | null;
}

export function IdentityMelcoModelsCurrencyFromJSON(json: any): IdentityMelcoModelsCurrency {
    return IdentityMelcoModelsCurrencyFromJSONTyped(json, false);
}

export function IdentityMelcoModelsCurrencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentityMelcoModelsCurrency {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'symbol': !exists(json, 'symbol') ? undefined : json['symbol'],
    };
}

export function IdentityMelcoModelsCurrencyToJSON(value?: IdentityMelcoModelsCurrency | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
        'symbol': value.symbol,
    };
}


