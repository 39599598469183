/* tslint:disable */
/* eslint-disable */
/**
 * Melco Identity APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IdentityMelcoModelsBoolResponse,
    IdentityMelcoModelsBoolResponseFromJSON,
    IdentityMelcoModelsBoolResponseToJSON,
    IdentityMelcoModelsCancelSerialNumber,
    IdentityMelcoModelsCancelSerialNumberFromJSON,
    IdentityMelcoModelsCancelSerialNumberToJSON,
    IdentityMelcoModelsError,
    IdentityMelcoModelsErrorFromJSON,
    IdentityMelcoModelsErrorToJSON,
    IdentityMelcoModelsGenerateSerialNumber,
    IdentityMelcoModelsGenerateSerialNumberFromJSON,
    IdentityMelcoModelsGenerateSerialNumberToJSON,
    IdentityMelcoModelsGenericList1MelcoModelsDocContent,
    IdentityMelcoModelsGenericList1MelcoModelsDocContentFromJSON,
    IdentityMelcoModelsGenericList1MelcoModelsDocContentToJSON,
    IdentityMelcoModelsGenericList1MelcoModelsProductListItem,
    IdentityMelcoModelsGenericList1MelcoModelsProductListItemFromJSON,
    IdentityMelcoModelsGenericList1MelcoModelsProductListItemToJSON,
    IdentityMelcoModelsGenericList1MelcoModelsProductPermissions,
    IdentityMelcoModelsGenericList1MelcoModelsProductPermissionsFromJSON,
    IdentityMelcoModelsGenericList1MelcoModelsProductPermissionsToJSON,
    IdentityMelcoModelsGenericList1MelcoModelsProductScopeListItem,
    IdentityMelcoModelsGenericList1MelcoModelsProductScopeListItemFromJSON,
    IdentityMelcoModelsGenericList1MelcoModelsProductScopeListItemToJSON,
    IdentityMelcoModelsGenericList1MelcoModelsProductVersionListItem,
    IdentityMelcoModelsGenericList1MelcoModelsProductVersionListItemFromJSON,
    IdentityMelcoModelsGenericList1MelcoModelsProductVersionListItemToJSON,
    IdentityMelcoModelsGenericList1MelcoModelsSerialNumberListItem,
    IdentityMelcoModelsGenericList1MelcoModelsSerialNumberListItemFromJSON,
    IdentityMelcoModelsGenericList1MelcoModelsSerialNumberListItemToJSON,
    IdentityMelcoModelsGenericList1MelcoModelsSubscriptionListItem,
    IdentityMelcoModelsGenericList1MelcoModelsSubscriptionListItemFromJSON,
    IdentityMelcoModelsGenericList1MelcoModelsSubscriptionListItemToJSON,
    IdentityMelcoModelsProduct,
    IdentityMelcoModelsProductFromJSON,
    IdentityMelcoModelsProductToJSON,
    IdentityMelcoModelsUpdateProduct,
    IdentityMelcoModelsUpdateProductFromJSON,
    IdentityMelcoModelsUpdateProductToJSON,
} from '../models';

export interface ProductsApiLicensesCancelLicenseRequest {
    serialNumber: string;
    identityMelcoModelsCancelSerialNumber?: IdentityMelcoModelsCancelSerialNumber;
}

export interface ProductsApiLicensesExportLicensesRequest {
    searchText?: string;
    serialNumber?: string;
    productCodes?: string;
    subscriptionCode?: string;
    referralCode?: string;
    batch?: string;
    cancelled?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
    versionId?: string;
}

export interface ProductsApiLicensesGenerateLicenseRequest {
    identityMelcoModelsGenerateSerialNumber?: IdentityMelcoModelsGenerateSerialNumber;
}

export interface ProductsApiLicensesSearchRequest {
    searchText?: string;
    serialNumber?: string;
    productCodes?: string;
    subscriptionCode?: string;
    referralCode?: string;
    batch?: string;
    cancelled?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
    versionId?: string;
    typeList?: string;
}

export interface ProductsApiProductsDocumentRequest {
    productCode: string;
    id: string;
    lang?: string;
}

export interface ProductsApiProductsEditProductRequest {
    productCode: string;
    identityMelcoModelsUpdateProduct?: IdentityMelcoModelsUpdateProduct;
}

export interface ProductsApiProductsGetListRequest {
    searchText?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
}

export interface ProductsApiProductsGetModuleListRequest {
    productCodeList?: string;
    versionNumberList?: string;
}

export interface ProductsApiProductsGetProductRequest {
    productCode: string;
}

export interface ProductsApiProductsGetVersionListRequest {
    productCode: string;
    searchText?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
}

export interface ProductsApiProductsPdfDocumentRequest {
    productCode: string;
    id: string;
    download?: boolean;
}

export interface ProductsApiProductsPermissionsRequest {
    productCodeList?: string;
}

export interface ProductsApiProductsScopesRequest {
    productCodeList?: string;
}

/**
 * 
 */
export class ProductsApi extends runtime.BaseAPI {

    /**
     */
    async licensesCancelLicenseRaw(requestParameters: ProductsApiLicensesCancelLicenseRequest): Promise<runtime.ApiResponse<IdentityMelcoModelsBoolResponse>> {
        if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
            throw new runtime.RequiredError('serialNumber','Required parameter requestParameters.serialNumber was null or undefined when calling licensesCancelLicense.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/products/licenses/{serial_number}/cancel`.replace(`{${"serial_number"}}`, encodeURIComponent(String(requestParameters.serialNumber))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IdentityMelcoModelsCancelSerialNumberToJSON(requestParameters.identityMelcoModelsCancelSerialNumber),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     */
    async licensesCancelLicense(requestParameters: ProductsApiLicensesCancelLicenseRequest): Promise<IdentityMelcoModelsBoolResponse> {
        const response = await this.licensesCancelLicenseRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async licensesExportLicensesRaw(requestParameters: ProductsApiLicensesExportLicensesRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.serialNumber !== undefined) {
            queryParameters['serial_number'] = requestParameters.serialNumber;
        }

        if (requestParameters.productCodes !== undefined) {
            queryParameters['product_codes'] = requestParameters.productCodes;
        }

        if (requestParameters.subscriptionCode !== undefined) {
            queryParameters['subscription_code'] = requestParameters.subscriptionCode;
        }

        if (requestParameters.referralCode !== undefined) {
            queryParameters['referral_code'] = requestParameters.referralCode;
        }

        if (requestParameters.batch !== undefined) {
            queryParameters['batch'] = requestParameters.batch;
        }

        if (requestParameters.cancelled !== undefined) {
            queryParameters['cancelled'] = requestParameters.cancelled;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.versionId !== undefined) {
            queryParameters['version_id'] = requestParameters.versionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/licenses/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async licensesExportLicenses(requestParameters: ProductsApiLicensesExportLicensesRequest): Promise<Blob> {
        const response = await this.licensesExportLicensesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async licensesGenerateLicenseRaw(requestParameters: ProductsApiLicensesGenerateLicenseRequest): Promise<runtime.ApiResponse<IdentityMelcoModelsGenericList1MelcoModelsSerialNumberListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/products/licenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IdentityMelcoModelsGenerateSerialNumberToJSON(requestParameters.identityMelcoModelsGenerateSerialNumber),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsGenericList1MelcoModelsSerialNumberListItemFromJSON(jsonValue));
    }

    /**
     */
    async licensesGenerateLicense(requestParameters: ProductsApiLicensesGenerateLicenseRequest): Promise<IdentityMelcoModelsGenericList1MelcoModelsSerialNumberListItem> {
        const response = await this.licensesGenerateLicenseRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async licensesSearchRaw(requestParameters: ProductsApiLicensesSearchRequest): Promise<runtime.ApiResponse<IdentityMelcoModelsGenericList1MelcoModelsSerialNumberListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.serialNumber !== undefined) {
            queryParameters['serial_number'] = requestParameters.serialNumber;
        }

        if (requestParameters.productCodes !== undefined) {
            queryParameters['product_codes'] = requestParameters.productCodes;
        }

        if (requestParameters.subscriptionCode !== undefined) {
            queryParameters['subscription_code'] = requestParameters.subscriptionCode;
        }

        if (requestParameters.referralCode !== undefined) {
            queryParameters['referral_code'] = requestParameters.referralCode;
        }

        if (requestParameters.batch !== undefined) {
            queryParameters['batch'] = requestParameters.batch;
        }

        if (requestParameters.cancelled !== undefined) {
            queryParameters['cancelled'] = requestParameters.cancelled;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.versionId !== undefined) {
            queryParameters['version_id'] = requestParameters.versionId;
        }

        if (requestParameters.typeList !== undefined) {
            queryParameters['type_list'] = requestParameters.typeList;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/licenses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsGenericList1MelcoModelsSerialNumberListItemFromJSON(jsonValue));
    }

    /**
     */
    async licensesSearch(requestParameters: ProductsApiLicensesSearchRequest): Promise<IdentityMelcoModelsGenericList1MelcoModelsSerialNumberListItem> {
        const response = await this.licensesSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productsDocumentRaw(requestParameters: ProductsApiProductsDocumentRequest): Promise<runtime.ApiResponse<IdentityMelcoModelsGenericList1MelcoModelsDocContent>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling productsDocument.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productsDocument.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.lang !== undefined) {
            queryParameters['Lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/{product_code}/documents/{Id}`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))).replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsGenericList1MelcoModelsDocContentFromJSON(jsonValue));
    }

    /**
     */
    async productsDocument(requestParameters: ProductsApiProductsDocumentRequest): Promise<IdentityMelcoModelsGenericList1MelcoModelsDocContent> {
        const response = await this.productsDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productsEditProductRaw(requestParameters: ProductsApiProductsEditProductRequest): Promise<runtime.ApiResponse<IdentityMelcoModelsProduct>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling productsEditProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/products/{product_code}`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IdentityMelcoModelsUpdateProductToJSON(requestParameters.identityMelcoModelsUpdateProduct),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsProductFromJSON(jsonValue));
    }

    /**
     */
    async productsEditProduct(requestParameters: ProductsApiProductsEditProductRequest): Promise<IdentityMelcoModelsProduct> {
        const response = await this.productsEditProductRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productsGetListRaw(requestParameters: ProductsApiProductsGetListRequest): Promise<runtime.ApiResponse<IdentityMelcoModelsGenericList1MelcoModelsProductListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsGenericList1MelcoModelsProductListItemFromJSON(jsonValue));
    }

    /**
     */
    async productsGetList(requestParameters: ProductsApiProductsGetListRequest): Promise<IdentityMelcoModelsGenericList1MelcoModelsProductListItem> {
        const response = await this.productsGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productsGetModuleListRaw(requestParameters: ProductsApiProductsGetModuleListRequest): Promise<runtime.ApiResponse<IdentityMelcoModelsGenericList1MelcoModelsSubscriptionListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.productCodeList !== undefined) {
            queryParameters['product_code_list'] = requestParameters.productCodeList;
        }

        if (requestParameters.versionNumberList !== undefined) {
            queryParameters['version_number_list'] = requestParameters.versionNumberList;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/subscriptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsGenericList1MelcoModelsSubscriptionListItemFromJSON(jsonValue));
    }

    /**
     */
    async productsGetModuleList(requestParameters: ProductsApiProductsGetModuleListRequest): Promise<IdentityMelcoModelsGenericList1MelcoModelsSubscriptionListItem> {
        const response = await this.productsGetModuleListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productsGetProductRaw(requestParameters: ProductsApiProductsGetProductRequest): Promise<runtime.ApiResponse<IdentityMelcoModelsProduct>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling productsGetProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/{product_code}`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsProductFromJSON(jsonValue));
    }

    /**
     */
    async productsGetProduct(requestParameters: ProductsApiProductsGetProductRequest): Promise<IdentityMelcoModelsProduct> {
        const response = await this.productsGetProductRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productsGetVersionListRaw(requestParameters: ProductsApiProductsGetVersionListRequest): Promise<runtime.ApiResponse<IdentityMelcoModelsGenericList1MelcoModelsProductVersionListItem>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling productsGetVersionList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/{product_code}/versions`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsGenericList1MelcoModelsProductVersionListItemFromJSON(jsonValue));
    }

    /**
     */
    async productsGetVersionList(requestParameters: ProductsApiProductsGetVersionListRequest): Promise<IdentityMelcoModelsGenericList1MelcoModelsProductVersionListItem> {
        const response = await this.productsGetVersionListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productsPdfDocumentRaw(requestParameters: ProductsApiProductsPdfDocumentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling productsPdfDocument.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productsPdfDocument.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.download !== undefined) {
            queryParameters['Download'] = requestParameters.download;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/{product_code}/documents/pdf/{Id}`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))).replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productsPdfDocument(requestParameters: ProductsApiProductsPdfDocumentRequest): Promise<void> {
        await this.productsPdfDocumentRaw(requestParameters);
    }

    /**
     */
    async productsPermissionsRaw(requestParameters: ProductsApiProductsPermissionsRequest): Promise<runtime.ApiResponse<IdentityMelcoModelsGenericList1MelcoModelsProductPermissions>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.productCodeList !== undefined) {
            queryParameters['product_code_list'] = requestParameters.productCodeList;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/permissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsGenericList1MelcoModelsProductPermissionsFromJSON(jsonValue));
    }

    /**
     */
    async productsPermissions(requestParameters: ProductsApiProductsPermissionsRequest): Promise<IdentityMelcoModelsGenericList1MelcoModelsProductPermissions> {
        const response = await this.productsPermissionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productsScopesRaw(requestParameters: ProductsApiProductsScopesRequest): Promise<runtime.ApiResponse<IdentityMelcoModelsGenericList1MelcoModelsProductScopeListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.productCodeList !== undefined) {
            queryParameters['product_code_list'] = requestParameters.productCodeList;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/apikeys/scopes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsGenericList1MelcoModelsProductScopeListItemFromJSON(jsonValue));
    }

    /**
     */
    async productsScopes(requestParameters: ProductsApiProductsScopesRequest): Promise<IdentityMelcoModelsGenericList1MelcoModelsProductScopeListItem> {
        const response = await this.productsScopesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productsStartBetaRaw(): Promise<runtime.ApiResponse<IdentityMelcoModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/subscriptions/reporting/start/beta`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     */
    async productsStartBeta(): Promise<IdentityMelcoModelsBoolResponse> {
        const response = await this.productsStartBetaRaw();
        return await response.value();
    }

}
