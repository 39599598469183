/* tslint:disable */
/* eslint-disable */
/**
 * Melco ControlCenter APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ControlcenterMelcoControlCenterModelsControlCenterAppSetting,
    ControlcenterMelcoControlCenterModelsControlCenterAppSettingFromJSON,
    ControlcenterMelcoControlCenterModelsControlCenterAppSettingToJSON,
    ControlcenterMelcoModelsError,
    ControlcenterMelcoModelsErrorFromJSON,
    ControlcenterMelcoModelsErrorToJSON,
} from '../models';

/**
 * 
 */
export class ControlCenterSettingsApi extends runtime.BaseAPI {

    /**
     */
    async settingsGetControlCenterAppSettingRaw(): Promise<runtime.ApiResponse<ControlcenterMelcoControlCenterModelsControlCenterAppSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/control_center/settings/app`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoControlCenterModelsControlCenterAppSettingFromJSON(jsonValue));
    }

    /**
     */
    async settingsGetControlCenterAppSetting(): Promise<ControlcenterMelcoControlCenterModelsControlCenterAppSetting> {
        const response = await this.settingsGetControlCenterAppSettingRaw();
        return await response.value();
    }

}
