/* tslint:disable */
/* eslint-disable */
/**
 * Melco ControlCenter APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ControlcenterMelcoModelsCustomWarning,
    ControlcenterMelcoModelsCustomWarningFromJSON,
    ControlcenterMelcoModelsCustomWarningFromJSONTyped,
    ControlcenterMelcoModelsCustomWarningToJSON,
    ControlcenterMelcoModelsEmailListItem,
    ControlcenterMelcoModelsEmailListItemFromJSON,
    ControlcenterMelcoModelsEmailListItemFromJSONTyped,
    ControlcenterMelcoModelsEmailListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface ControlcenterMelcoModelsGenericList1MelcoModelsEmailListItem
 */
export interface ControlcenterMelcoModelsGenericList1MelcoModelsEmailListItem {
    /**
     * 
     * @type {Array<ControlcenterMelcoModelsEmailListItem>}
     * @memberof ControlcenterMelcoModelsGenericList1MelcoModelsEmailListItem
     */
    list: Array<ControlcenterMelcoModelsEmailListItem>;
    /**
     * 
     * @type {Array<ControlcenterMelcoModelsCustomWarning>}
     * @memberof ControlcenterMelcoModelsGenericList1MelcoModelsEmailListItem
     */
    warning_list?: Array<ControlcenterMelcoModelsCustomWarning> | null;
    /**
     * 
     * @type {number}
     * @memberof ControlcenterMelcoModelsGenericList1MelcoModelsEmailListItem
     */
    total: number;
}

export function ControlcenterMelcoModelsGenericList1MelcoModelsEmailListItemFromJSON(json: any): ControlcenterMelcoModelsGenericList1MelcoModelsEmailListItem {
    return ControlcenterMelcoModelsGenericList1MelcoModelsEmailListItemFromJSONTyped(json, false);
}

export function ControlcenterMelcoModelsGenericList1MelcoModelsEmailListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlcenterMelcoModelsGenericList1MelcoModelsEmailListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(ControlcenterMelcoModelsEmailListItemFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(ControlcenterMelcoModelsCustomWarningFromJSON)),
        'total': json['total'],
    };
}

export function ControlcenterMelcoModelsGenericList1MelcoModelsEmailListItemToJSON(value?: ControlcenterMelcoModelsGenericList1MelcoModelsEmailListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value.list as Array<any>).map(ControlcenterMelcoModelsEmailListItemToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(ControlcenterMelcoModelsCustomWarningToJSON)),
        'total': value.total,
    };
}


