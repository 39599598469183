/* tslint:disable */
/* eslint-disable */
/**
 * Melco ControlCenter APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ControlcenterMelcoModelsCustomWarning,
    ControlcenterMelcoModelsCustomWarningFromJSON,
    ControlcenterMelcoModelsCustomWarningFromJSONTyped,
    ControlcenterMelcoModelsCustomWarningToJSON,
} from './';

/**
 * 
 * @export
 * @interface ControlcenterMelcoModelsSupplierProduct
 */
export interface ControlcenterMelcoModelsSupplierProduct {
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsSupplierProduct
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsSupplierProduct
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsSupplierProduct
     */
    supplier_id: string;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsSupplierProduct
     */
    supplier_name: string;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsSupplierProduct
     */
    code: string;
    /**
     * 
     * @type {number}
     * @memberof ControlcenterMelcoModelsSupplierProduct
     */
    publish_state: number;
    /**
     * 
     * @type {Date}
     * @memberof ControlcenterMelcoModelsSupplierProduct
     */
    created_date: Date;
    /**
     * 
     * @type {Date}
     * @memberof ControlcenterMelcoModelsSupplierProduct
     */
    publish_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ControlcenterMelcoModelsSupplierProduct
     */
    updated_date?: Date | null;
    /**
     * 
     * @type {Array<ControlcenterMelcoModelsCustomWarning>}
     * @memberof ControlcenterMelcoModelsSupplierProduct
     */
    warning_list?: Array<ControlcenterMelcoModelsCustomWarning> | null;
}

export function ControlcenterMelcoModelsSupplierProductFromJSON(json: any): ControlcenterMelcoModelsSupplierProduct {
    return ControlcenterMelcoModelsSupplierProductFromJSONTyped(json, false);
}

export function ControlcenterMelcoModelsSupplierProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlcenterMelcoModelsSupplierProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'supplier_id': json['supplier_id'],
        'supplier_name': json['supplier_name'],
        'code': json['code'],
        'publish_state': json['publish_state'],
        'created_date': (new Date(json['created_date'])),
        'publish_date': !exists(json, 'publish_date') ? undefined : (json['publish_date'] === null ? null : new Date(json['publish_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(ControlcenterMelcoModelsCustomWarningFromJSON)),
    };
}

export function ControlcenterMelcoModelsSupplierProductToJSON(value?: ControlcenterMelcoModelsSupplierProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'supplier_id': value.supplier_id,
        'supplier_name': value.supplier_name,
        'code': value.code,
        'publish_state': value.publish_state,
        'created_date': (value.created_date.toISOString()),
        'publish_date': value.publish_date === undefined ? undefined : (value.publish_date === null ? null : value.publish_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(ControlcenterMelcoModelsCustomWarningToJSON)),
    };
}


