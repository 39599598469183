/* tslint:disable */
/* eslint-disable */
/**
 * Melco ControlCenter APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ControlcenterMelcoModelsCustomWarning,
    ControlcenterMelcoModelsCustomWarningFromJSON,
    ControlcenterMelcoModelsCustomWarningFromJSONTyped,
    ControlcenterMelcoModelsCustomWarningToJSON,
    ControlcenterMelcoModelsSupplierProduct,
    ControlcenterMelcoModelsSupplierProductFromJSON,
    ControlcenterMelcoModelsSupplierProductFromJSONTyped,
    ControlcenterMelcoModelsSupplierProductToJSON,
} from './';

/**
 * 
 * @export
 * @interface ControlcenterMelcoModelsGenericList1MelcoModelsSupplierProduct
 */
export interface ControlcenterMelcoModelsGenericList1MelcoModelsSupplierProduct {
    /**
     * 
     * @type {Array<ControlcenterMelcoModelsSupplierProduct>}
     * @memberof ControlcenterMelcoModelsGenericList1MelcoModelsSupplierProduct
     */
    list: Array<ControlcenterMelcoModelsSupplierProduct>;
    /**
     * 
     * @type {Array<ControlcenterMelcoModelsCustomWarning>}
     * @memberof ControlcenterMelcoModelsGenericList1MelcoModelsSupplierProduct
     */
    warning_list?: Array<ControlcenterMelcoModelsCustomWarning> | null;
    /**
     * 
     * @type {number}
     * @memberof ControlcenterMelcoModelsGenericList1MelcoModelsSupplierProduct
     */
    total: number;
}

export function ControlcenterMelcoModelsGenericList1MelcoModelsSupplierProductFromJSON(json: any): ControlcenterMelcoModelsGenericList1MelcoModelsSupplierProduct {
    return ControlcenterMelcoModelsGenericList1MelcoModelsSupplierProductFromJSONTyped(json, false);
}

export function ControlcenterMelcoModelsGenericList1MelcoModelsSupplierProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlcenterMelcoModelsGenericList1MelcoModelsSupplierProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(ControlcenterMelcoModelsSupplierProductFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(ControlcenterMelcoModelsCustomWarningFromJSON)),
        'total': json['total'],
    };
}

export function ControlcenterMelcoModelsGenericList1MelcoModelsSupplierProductToJSON(value?: ControlcenterMelcoModelsGenericList1MelcoModelsSupplierProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value.list as Array<any>).map(ControlcenterMelcoModelsSupplierProductToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(ControlcenterMelcoModelsCustomWarningToJSON)),
        'total': value.total,
    };
}


