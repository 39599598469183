/* tslint:disable */
/* eslint-disable */
/**
 * Melco ControlCenter APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ControlcenterMelcoModelsHoopCategory,
    ControlcenterMelcoModelsHoopCategoryFromJSON,
    ControlcenterMelcoModelsHoopCategoryFromJSONTyped,
    ControlcenterMelcoModelsHoopCategoryToJSON,
    ControlcenterMelcoModelsHoopMachine,
    ControlcenterMelcoModelsHoopMachineFromJSON,
    ControlcenterMelcoModelsHoopMachineFromJSONTyped,
    ControlcenterMelcoModelsHoopMachineToJSON,
    ControlcenterMelcoModelsHoopProduct,
    ControlcenterMelcoModelsHoopProductFromJSON,
    ControlcenterMelcoModelsHoopProductFromJSONTyped,
    ControlcenterMelcoModelsHoopProductToJSON,
} from './';

/**
 * 
 * @export
 * @interface ControlcenterMelcoModelsCreateHoop
 */
export interface ControlcenterMelcoModelsCreateHoop {
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsCreateHoop
     */
    english_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsCreateHoop
     */
    metric_name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlcenterMelcoModelsCreateHoop
     */
    version?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlcenterMelcoModelsCreateHoop
     */
    width_points?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlcenterMelcoModelsCreateHoop
     */
    height_points?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsCreateHoop
     */
    presser_foot?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlcenterMelcoModelsCreateHoop
     */
    enable_templates?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsCreateHoop
     */
    backward_compatibility_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsCreateHoop
     */
    type?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlcenterMelcoModelsCreateHoop
     */
    discontinued?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsCreateHoop
     */
    supplier_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsCreateHoop
     */
    supplier_product_id?: string | null;
    /**
     * 
     * @type {Array<ControlcenterMelcoModelsHoopCategory>}
     * @memberof ControlcenterMelcoModelsCreateHoop
     */
    category_list?: Array<ControlcenterMelcoModelsHoopCategory> | null;
    /**
     * 
     * @type {Array<ControlcenterMelcoModelsHoopProduct>}
     * @memberof ControlcenterMelcoModelsCreateHoop
     */
    product_list?: Array<ControlcenterMelcoModelsHoopProduct> | null;
    /**
     * 
     * @type {Array<ControlcenterMelcoModelsHoopMachine>}
     * @memberof ControlcenterMelcoModelsCreateHoop
     */
    machine_list?: Array<ControlcenterMelcoModelsHoopMachine> | null;
    /**
     * 
     * @type {number}
     * @memberof ControlcenterMelcoModelsCreateHoop
     */
    publish_state?: number;
}

export function ControlcenterMelcoModelsCreateHoopFromJSON(json: any): ControlcenterMelcoModelsCreateHoop {
    return ControlcenterMelcoModelsCreateHoopFromJSONTyped(json, false);
}

export function ControlcenterMelcoModelsCreateHoopFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlcenterMelcoModelsCreateHoop {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'english_name': !exists(json, 'english_name') ? undefined : json['english_name'],
        'metric_name': !exists(json, 'metric_name') ? undefined : json['metric_name'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'width_points': !exists(json, 'width_points') ? undefined : json['width_points'],
        'height_points': !exists(json, 'height_points') ? undefined : json['height_points'],
        'presser_foot': !exists(json, 'presser_foot') ? undefined : json['presser_foot'],
        'enable_templates': !exists(json, 'enable_templates') ? undefined : json['enable_templates'],
        'backward_compatibility_id': !exists(json, 'backward_compatibility_id') ? undefined : json['backward_compatibility_id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'discontinued': !exists(json, 'discontinued') ? undefined : json['discontinued'],
        'supplier_id': !exists(json, 'supplier_id') ? undefined : json['supplier_id'],
        'supplier_product_id': !exists(json, 'supplier_product_id') ? undefined : json['supplier_product_id'],
        'category_list': !exists(json, 'category_list') ? undefined : (json['category_list'] === null ? null : (json['category_list'] as Array<any>).map(ControlcenterMelcoModelsHoopCategoryFromJSON)),
        'product_list': !exists(json, 'product_list') ? undefined : (json['product_list'] === null ? null : (json['product_list'] as Array<any>).map(ControlcenterMelcoModelsHoopProductFromJSON)),
        'machine_list': !exists(json, 'machine_list') ? undefined : (json['machine_list'] === null ? null : (json['machine_list'] as Array<any>).map(ControlcenterMelcoModelsHoopMachineFromJSON)),
        'publish_state': !exists(json, 'publish_state') ? undefined : json['publish_state'],
    };
}

export function ControlcenterMelcoModelsCreateHoopToJSON(value?: ControlcenterMelcoModelsCreateHoop | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'english_name': value.english_name,
        'metric_name': value.metric_name,
        'version': value.version,
        'width_points': value.width_points,
        'height_points': value.height_points,
        'presser_foot': value.presser_foot,
        'enable_templates': value.enable_templates,
        'backward_compatibility_id': value.backward_compatibility_id,
        'type': value.type,
        'discontinued': value.discontinued,
        'supplier_id': value.supplier_id,
        'supplier_product_id': value.supplier_product_id,
        'category_list': value.category_list === undefined ? undefined : (value.category_list === null ? null : (value.category_list as Array<any>).map(ControlcenterMelcoModelsHoopCategoryToJSON)),
        'product_list': value.product_list === undefined ? undefined : (value.product_list === null ? null : (value.product_list as Array<any>).map(ControlcenterMelcoModelsHoopProductToJSON)),
        'machine_list': value.machine_list === undefined ? undefined : (value.machine_list === null ? null : (value.machine_list as Array<any>).map(ControlcenterMelcoModelsHoopMachineToJSON)),
        'publish_state': value.publish_state,
    };
}


