/* tslint:disable */
/* eslint-disable */
/**
 * Melco APIService APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiserviceMelcoModelsDefaultAppSetting,
    ApiserviceMelcoModelsDefaultAppSettingFromJSON,
    ApiserviceMelcoModelsDefaultAppSettingToJSON,
    ApiserviceMelcoModelsError,
    ApiserviceMelcoModelsErrorFromJSON,
    ApiserviceMelcoModelsErrorToJSON,
} from '../models';

/**
 * 
 */
export class ApiServiceSettingsApi extends runtime.BaseAPI {

    /**
     */
    async settingsGetApiServiceAppSettingRaw(): Promise<runtime.ApiResponse<ApiserviceMelcoModelsDefaultAppSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api_service/settings/app`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiserviceMelcoModelsDefaultAppSettingFromJSON(jsonValue));
    }

    /**
     */
    async settingsGetApiServiceAppSetting(): Promise<ApiserviceMelcoModelsDefaultAppSetting> {
        const response = await this.settingsGetApiServiceAppSettingRaw();
        return await response.value();
    }

}
