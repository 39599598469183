import { ApiServiceSettingsApi } from "../../client-api-service";
import { APIOptionsWithoutAPIClass, useAPI } from "./useAPI";

export const useApiServiceSettingsAPI = <T>({
  initialValue,
  handleRequest,
}: APIOptionsWithoutAPIClass<T, ApiServiceSettingsApi>) => {
  return useAPI<T, ApiServiceSettingsApi>({
    initialValue,
    APIClass: ApiServiceSettingsApi,
    handleRequest,
  });
};
