/* tslint:disable */
/* eslint-disable */
/**
 * Melco Identity APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IdentityMelcoModelsPermissionsSubListItem,
    IdentityMelcoModelsPermissionsSubListItemFromJSON,
    IdentityMelcoModelsPermissionsSubListItemFromJSONTyped,
    IdentityMelcoModelsPermissionsSubListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface IdentityMelcoModelsProductPermissions
 */
export interface IdentityMelcoModelsProductPermissions {
    /**
     * 
     * @type {string}
     * @memberof IdentityMelcoModelsProductPermissions
     */
    product_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityMelcoModelsProductPermissions
     */
    product_name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityMelcoModelsProductPermissions
     */
    is_removable?: boolean;
    /**
     * 
     * @type {Array<IdentityMelcoModelsPermissionsSubListItem>}
     * @memberof IdentityMelcoModelsProductPermissions
     */
    permissions?: Array<IdentityMelcoModelsPermissionsSubListItem> | null;
}

export function IdentityMelcoModelsProductPermissionsFromJSON(json: any): IdentityMelcoModelsProductPermissions {
    return IdentityMelcoModelsProductPermissionsFromJSONTyped(json, false);
}

export function IdentityMelcoModelsProductPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentityMelcoModelsProductPermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
        'product_name': !exists(json, 'product_name') ? undefined : json['product_name'],
        'is_removable': !exists(json, 'is_removable') ? undefined : json['is_removable'],
        'permissions': !exists(json, 'permissions') ? undefined : (json['permissions'] === null ? null : (json['permissions'] as Array<any>).map(IdentityMelcoModelsPermissionsSubListItemFromJSON)),
    };
}

export function IdentityMelcoModelsProductPermissionsToJSON(value?: IdentityMelcoModelsProductPermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_code': value.product_code,
        'product_name': value.product_name,
        'is_removable': value.is_removable,
        'permissions': value.permissions === undefined ? undefined : (value.permissions === null ? null : (value.permissions as Array<any>).map(IdentityMelcoModelsPermissionsSubListItemToJSON)),
    };
}


