/* tslint:disable */
/* eslint-disable */
/**
 * Melco ControlCenter APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ControlcenterMelcoControlCenterModelsChangeLicenseLevel,
    ControlcenterMelcoControlCenterModelsChangeLicenseLevelFromJSON,
    ControlcenterMelcoControlCenterModelsChangeLicenseLevelToJSON,
    ControlcenterMelcoControlCenterModelsUserLicense,
    ControlcenterMelcoControlCenterModelsUserLicenseFromJSON,
    ControlcenterMelcoControlCenterModelsUserLicenseToJSON,
    ControlcenterMelcoControlCenterModelsUserSerialNumberResponse,
    ControlcenterMelcoControlCenterModelsUserSerialNumberResponseFromJSON,
    ControlcenterMelcoControlCenterModelsUserSerialNumberResponseToJSON,
    ControlcenterMelcoModelsBoolResponse,
    ControlcenterMelcoModelsBoolResponseFromJSON,
    ControlcenterMelcoModelsBoolResponseToJSON,
    ControlcenterMelcoModelsBridgeSerialNumber,
    ControlcenterMelcoModelsBridgeSerialNumberFromJSON,
    ControlcenterMelcoModelsBridgeSerialNumberToJSON,
    ControlcenterMelcoModelsError,
    ControlcenterMelcoModelsErrorFromJSON,
    ControlcenterMelcoModelsErrorToJSON,
    ControlcenterMelcoModelsLegacySerialNumber,
    ControlcenterMelcoModelsLegacySerialNumberFromJSON,
    ControlcenterMelcoModelsLegacySerialNumberToJSON,
    ControlcenterMelcoModelsLicenseCreateResponse,
    ControlcenterMelcoModelsLicenseCreateResponseFromJSON,
    ControlcenterMelcoModelsLicenseCreateResponseToJSON,
    ControlcenterMelcoModelsUpgradeLicense,
    ControlcenterMelcoModelsUpgradeLicenseFromJSON,
    ControlcenterMelcoModelsUpgradeLicenseToJSON,
} from '../models';

export interface LicenseManagerApiLicenseManagerLicensesManagerCreateRequest {
    controlcenterMelcoControlCenterModelsUserLicense?: ControlcenterMelcoControlCenterModelsUserLicense;
}

export interface LicenseManagerApiLicenseManagerLicensesManagerEmailRequest {
    controlcenterMelcoModelsBridgeSerialNumber?: ControlcenterMelcoModelsBridgeSerialNumber;
}

export interface LicenseManagerApiLicenseManagerLicensesManagerUpdateRequest {
    controlcenterMelcoControlCenterModelsChangeLicenseLevel?: ControlcenterMelcoControlCenterModelsChangeLicenseLevel;
}

export interface LicenseManagerApiLicenseManagerLicensesManagerUpgradeRequest {
    controlcenterMelcoModelsUpgradeLicense?: ControlcenterMelcoModelsUpgradeLicense;
}

/**
 * 
 */
export class LicenseManagerApi extends runtime.BaseAPI {

    /**
     */
    async licenseManagerLicensesManagerCreateRaw(requestParameters: LicenseManagerApiLicenseManagerLicensesManagerCreateRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsLicenseCreateResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/license/manager`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlcenterMelcoControlCenterModelsUserLicenseToJSON(requestParameters.controlcenterMelcoControlCenterModelsUserLicense),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsLicenseCreateResponseFromJSON(jsonValue));
    }

    /**
     */
    async licenseManagerLicensesManagerCreate(requestParameters: LicenseManagerApiLicenseManagerLicensesManagerCreateRequest): Promise<ControlcenterMelcoModelsLicenseCreateResponse> {
        const response = await this.licenseManagerLicensesManagerCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async licenseManagerLicensesManagerEmailRaw(requestParameters: LicenseManagerApiLicenseManagerLicensesManagerEmailRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/license/manager/change_email`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ControlcenterMelcoModelsBridgeSerialNumberToJSON(requestParameters.controlcenterMelcoModelsBridgeSerialNumber),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     */
    async licenseManagerLicensesManagerEmail(requestParameters: LicenseManagerApiLicenseManagerLicensesManagerEmailRequest): Promise<ControlcenterMelcoModelsBoolResponse> {
        const response = await this.licenseManagerLicensesManagerEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async licenseManagerLicensesManagerLegacyRaw(): Promise<runtime.ApiResponse<ControlcenterMelcoModelsLegacySerialNumber>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/license/manager/legacy_license`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsLegacySerialNumberFromJSON(jsonValue));
    }

    /**
     */
    async licenseManagerLicensesManagerLegacy(): Promise<ControlcenterMelcoModelsLegacySerialNumber> {
        const response = await this.licenseManagerLicensesManagerLegacyRaw();
        return await response.value();
    }

    /**
     */
    async licenseManagerLicensesManagerUpdateRaw(requestParameters: LicenseManagerApiLicenseManagerLicensesManagerUpdateRequest): Promise<runtime.ApiResponse<ControlcenterMelcoControlCenterModelsUserSerialNumberResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/license/manager/update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ControlcenterMelcoControlCenterModelsChangeLicenseLevelToJSON(requestParameters.controlcenterMelcoControlCenterModelsChangeLicenseLevel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoControlCenterModelsUserSerialNumberResponseFromJSON(jsonValue));
    }

    /**
     */
    async licenseManagerLicensesManagerUpdate(requestParameters: LicenseManagerApiLicenseManagerLicensesManagerUpdateRequest): Promise<ControlcenterMelcoControlCenterModelsUserSerialNumberResponse> {
        const response = await this.licenseManagerLicensesManagerUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async licenseManagerLicensesManagerUpgradeRaw(requestParameters: LicenseManagerApiLicenseManagerLicensesManagerUpgradeRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsLicenseCreateResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/license/manager/upgrade`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlcenterMelcoModelsUpgradeLicenseToJSON(requestParameters.controlcenterMelcoModelsUpgradeLicense),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsLicenseCreateResponseFromJSON(jsonValue));
    }

    /**
     */
    async licenseManagerLicensesManagerUpgrade(requestParameters: LicenseManagerApiLicenseManagerLicensesManagerUpgradeRequest): Promise<ControlcenterMelcoModelsLicenseCreateResponse> {
        const response = await this.licenseManagerLicensesManagerUpgradeRaw(requestParameters);
        return await response.value();
    }

}
