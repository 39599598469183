/* tslint:disable */
/* eslint-disable */
/**
 * Melco ControlCenter APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ControlcenterMelcoModelsEmail,
    ControlcenterMelcoModelsEmailFromJSON,
    ControlcenterMelcoModelsEmailToJSON,
    ControlcenterMelcoModelsError,
    ControlcenterMelcoModelsErrorFromJSON,
    ControlcenterMelcoModelsErrorToJSON,
    ControlcenterMelcoModelsGenericList1MelcoModelsEmailListItem,
    ControlcenterMelcoModelsGenericList1MelcoModelsEmailListItemFromJSON,
    ControlcenterMelcoModelsGenericList1MelcoModelsEmailListItemToJSON,
} from '../models';

export interface HistoryApiManageGetEmailRequest {
    id: string;
}

export interface HistoryApiManageSearchEmailsRequest {
    searchText?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
    emailAddress?: string;
    productCodeList?: string;
    startTimestamp?: Date;
    endTimestamp?: Date;
}

/**
 * 
 */
export class HistoryApi extends runtime.BaseAPI {

    /**
     */
    async manageGetEmailRaw(requestParameters: HistoryApiManageGetEmailRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsEmail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageGetEmail.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/history/emails/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsEmailFromJSON(jsonValue));
    }

    /**
     */
    async manageGetEmail(requestParameters: HistoryApiManageGetEmailRequest): Promise<ControlcenterMelcoModelsEmail> {
        const response = await this.manageGetEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async manageSearchEmailsRaw(requestParameters: HistoryApiManageSearchEmailsRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsGenericList1MelcoModelsEmailListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.emailAddress !== undefined) {
            queryParameters['email_address'] = requestParameters.emailAddress;
        }

        if (requestParameters.productCodeList !== undefined) {
            queryParameters['product_code_list'] = requestParameters.productCodeList;
        }

        if (requestParameters.startTimestamp !== undefined) {
            queryParameters['start_timestamp'] = (requestParameters.startTimestamp as any).toISOString();
        }

        if (requestParameters.endTimestamp !== undefined) {
            queryParameters['end_timestamp'] = (requestParameters.endTimestamp as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/history/emails`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsGenericList1MelcoModelsEmailListItemFromJSON(jsonValue));
    }

    /**
     */
    async manageSearchEmails(requestParameters: HistoryApiManageSearchEmailsRequest): Promise<ControlcenterMelcoModelsGenericList1MelcoModelsEmailListItem> {
        const response = await this.manageSearchEmailsRaw(requestParameters);
        return await response.value();
    }

}
