/* tslint:disable */
/* eslint-disable */
/**
 * Melco Identity APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IdentityMelcoModelsCustomWarning,
    IdentityMelcoModelsCustomWarningFromJSON,
    IdentityMelcoModelsCustomWarningFromJSONTyped,
    IdentityMelcoModelsCustomWarningToJSON,
    IdentityMelcoModelsProductPermissions,
    IdentityMelcoModelsProductPermissionsFromJSON,
    IdentityMelcoModelsProductPermissionsFromJSONTyped,
    IdentityMelcoModelsProductPermissionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface IdentityMelcoModelsGenericList1MelcoModelsProductPermissions
 */
export interface IdentityMelcoModelsGenericList1MelcoModelsProductPermissions {
    /**
     * 
     * @type {Array<IdentityMelcoModelsProductPermissions>}
     * @memberof IdentityMelcoModelsGenericList1MelcoModelsProductPermissions
     */
    list: Array<IdentityMelcoModelsProductPermissions>;
    /**
     * 
     * @type {Array<IdentityMelcoModelsCustomWarning>}
     * @memberof IdentityMelcoModelsGenericList1MelcoModelsProductPermissions
     */
    warning_list?: Array<IdentityMelcoModelsCustomWarning> | null;
    /**
     * 
     * @type {number}
     * @memberof IdentityMelcoModelsGenericList1MelcoModelsProductPermissions
     */
    total: number;
}

export function IdentityMelcoModelsGenericList1MelcoModelsProductPermissionsFromJSON(json: any): IdentityMelcoModelsGenericList1MelcoModelsProductPermissions {
    return IdentityMelcoModelsGenericList1MelcoModelsProductPermissionsFromJSONTyped(json, false);
}

export function IdentityMelcoModelsGenericList1MelcoModelsProductPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentityMelcoModelsGenericList1MelcoModelsProductPermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(IdentityMelcoModelsProductPermissionsFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(IdentityMelcoModelsCustomWarningFromJSON)),
        'total': json['total'],
    };
}

export function IdentityMelcoModelsGenericList1MelcoModelsProductPermissionsToJSON(value?: IdentityMelcoModelsGenericList1MelcoModelsProductPermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value.list as Array<any>).map(IdentityMelcoModelsProductPermissionsToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(IdentityMelcoModelsCustomWarningToJSON)),
        'total': value.total,
    };
}


