/* tslint:disable */
/* eslint-disable */
/**
 * Melco ControlCenter APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlcenterMelcoModelsCreateApiKey
 */
export interface ControlcenterMelcoModelsCreateApiKey {
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsCreateApiKey
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsCreateApiKey
     */
    sub_group?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsCreateApiKey
     */
    product_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsCreateApiKey
     */
    scope_id?: string | null;
}

export function ControlcenterMelcoModelsCreateApiKeyFromJSON(json: any): ControlcenterMelcoModelsCreateApiKey {
    return ControlcenterMelcoModelsCreateApiKeyFromJSONTyped(json, false);
}

export function ControlcenterMelcoModelsCreateApiKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlcenterMelcoModelsCreateApiKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'sub_group': !exists(json, 'sub_group') ? undefined : json['sub_group'],
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
        'scope_id': !exists(json, 'scope_id') ? undefined : json['scope_id'],
    };
}

export function ControlcenterMelcoModelsCreateApiKeyToJSON(value?: ControlcenterMelcoModelsCreateApiKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'sub_group': value.sub_group,
        'product_code': value.product_code,
        'scope_id': value.scope_id,
    };
}


