/* tslint:disable */
/* eslint-disable */
/**
 * Melco ControlCenter APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ControlcenterMelcoModelsError,
    ControlcenterMelcoModelsErrorFromJSON,
    ControlcenterMelcoModelsErrorToJSON,
    ControlcenterMelcoModelsGenericList1MelcoControlCenterModelsCategories,
    ControlcenterMelcoModelsGenericList1MelcoControlCenterModelsCategoriesFromJSON,
    ControlcenterMelcoModelsGenericList1MelcoControlCenterModelsCategoriesToJSON,
} from '../models';

export interface CategoriesApiCategoriesSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    publishState?: string;
    categoryType?: string;
}

/**
 * 
 */
export class CategoriesApi extends runtime.BaseAPI {

    /**
     */
    async categoriesSearchRaw(requestParameters: CategoriesApiCategoriesSearchRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsGenericList1MelcoControlCenterModelsCategories>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.categoryType !== undefined) {
            queryParameters['category_type'] = requestParameters.categoryType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsGenericList1MelcoControlCenterModelsCategoriesFromJSON(jsonValue));
    }

    /**
     */
    async categoriesSearch(requestParameters: CategoriesApiCategoriesSearchRequest): Promise<ControlcenterMelcoModelsGenericList1MelcoControlCenterModelsCategories> {
        const response = await this.categoriesSearchRaw(requestParameters);
        return await response.value();
    }

}
