import { FusionAnalyticsApi } from "../../client-fusion";
import { APIOptionsWithoutAPIClass, useAPI } from "./useAPI";

export const useFusionAnalyticsAPI = <T>({
  initialValue,
  handleRequest,
}: APIOptionsWithoutAPIClass<T, FusionAnalyticsApi>) => {
  return useAPI<T, FusionAnalyticsApi>({
    initialValue,
    APIClass: FusionAnalyticsApi,
    handleRequest,
  });
};
