import {
  DisplayFormErrors,
  FusionMelcoModelsDefaultAppSetting,
  SelectField,
  VerticalForm,
} from "melco-shared-logic";
import { useTranslation } from "react-i18next";

type DisplayBasicSettingsFormProps = {
  defaultSettings: FusionMelcoModelsDefaultAppSetting;
};

export const DisplayBasicSettingsForm: React.FC<
  DisplayBasicSettingsFormProps
> = ({ defaultSettings }) => {
  const { t } = useTranslation();

  return (
    <VerticalForm>
      <DisplayFormErrors />

      <SelectField
        name="measurement_unit"
        label={t("settings.basic.form.label.measurement_unit")}
        options={(defaultSettings.measurements ?? []).map((measurement) => {
          return {
            label: t([
              `settings.basic.form.values.measurement_unit.${measurement.key}` as any,
              measurement.display_text!,
            ]),
            value: measurement.key!,
          };
        })}
        showSearch
        optionFilterProp="label"
      />

      <SelectField
        name="default_embroidery_production_file_format"
        label={t(
          "settings.basic.form.label.default_embroidery_production_file_format"
        )}
        options={(defaultSettings.emb_formats ?? []).map((embFormat) => {
          return {
            label: t([
              `settings.basic.form.values.default_embroidery_production_file_format.${embFormat.key}` as any,
              embFormat.display_text!,
            ]),
            value: embFormat.key!,
          };
        })}
        showSearch
        optionFilterProp="label"
      />
    </VerticalForm>
  );
};
