/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoModelsError,
    MelcoModelsErrorFromJSON,
    MelcoModelsErrorToJSON,
    MelcoModelsGenericList1MelcoModelsRole,
    MelcoModelsGenericList1MelcoModelsRoleFromJSON,
    MelcoModelsGenericList1MelcoModelsRoleToJSON,
} from '../models';

export interface UserRolesApiGetRolesRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
}

/**
 * 
 */
export class UserRolesApi extends runtime.BaseAPI {

    /**
     * get roles
     */
    async getRolesRaw(requestParameters: UserRolesApiGetRolesRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsRole>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsRoleFromJSON(jsonValue));
    }

    /**
     * get roles
     */
    async getRoles(requestParameters: UserRolesApiGetRolesRequest): Promise<MelcoModelsGenericList1MelcoModelsRole> {
        const response = await this.getRolesRaw(requestParameters);
        return await response.value();
    }

}
