/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FusionMelcoModelsError,
    FusionMelcoModelsErrorFromJSON,
    FusionMelcoModelsErrorToJSON,
    FusionMelcoModelsGenericList1MelcoModelsSessionDailySummary,
    FusionMelcoModelsGenericList1MelcoModelsSessionDailySummaryFromJSON,
    FusionMelcoModelsGenericList1MelcoModelsSessionDailySummaryToJSON,
} from '../models';

export interface FusionAnalyticsApiSessionsFusionDailySummaryRequest {
    startTimestamp?: Date;
    endTimestamp?: Date;
}

/**
 * 
 */
export class FusionAnalyticsApi extends runtime.BaseAPI {

    /**
     */
    async sessionsFusionDailySummaryRaw(requestParameters: FusionAnalyticsApiSessionsFusionDailySummaryRequest): Promise<runtime.ApiResponse<FusionMelcoModelsGenericList1MelcoModelsSessionDailySummary>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startTimestamp !== undefined) {
            queryParameters['start_timestamp'] = (requestParameters.startTimestamp as any).toISOString();
        }

        if (requestParameters.endTimestamp !== undefined) {
            queryParameters['end_timestamp'] = (requestParameters.endTimestamp as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/sessions/dailysummary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FusionMelcoModelsGenericList1MelcoModelsSessionDailySummaryFromJSON(jsonValue));
    }

    /**
     */
    async sessionsFusionDailySummary(requestParameters: FusionAnalyticsApiSessionsFusionDailySummaryRequest): Promise<FusionMelcoModelsGenericList1MelcoModelsSessionDailySummary> {
        const response = await this.sessionsFusionDailySummaryRaw(requestParameters);
        return await response.value();
    }

}
