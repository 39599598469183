/* tslint:disable */
/* eslint-disable */
/**
 * Melco Identity APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IdentityMelcoModelsGender
 */
export interface IdentityMelcoModelsGender {
    /**
     * 
     * @type {string}
     * @memberof IdentityMelcoModelsGender
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityMelcoModelsGender
     */
    text?: string | null;
}

export function IdentityMelcoModelsGenderFromJSON(json: any): IdentityMelcoModelsGender {
    return IdentityMelcoModelsGenderFromJSONTyped(json, false);
}

export function IdentityMelcoModelsGenderFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentityMelcoModelsGender {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'text': !exists(json, 'text') ? undefined : json['text'],
    };
}

export function IdentityMelcoModelsGenderToJSON(value?: IdentityMelcoModelsGender | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'text': value.text,
    };
}


