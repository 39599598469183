import { useCallback } from "react";
import {
  FusionAnalyticsApi,
  MelcoModelsSessionDailySummary,
  useFusionAnalyticsAPI,
} from "melco-shared-logic";
import sortBy from "lodash-es/sortBy";

type SessionsData = {
  dailySummary: MelcoModelsSessionDailySummary[];
};

const initialValue = { dailySummary: [] };

export const useSessionsAnalytics = (
  startTimestamp?: Date,
  endTimestamp?: Date
) => {
  const handleRequest = useCallback(
    async (api: FusionAnalyticsApi) => {
      const response = await api.sessionsFusionDailySummary({
        startTimestamp,
        endTimestamp,
      });

      const unsortedDailySummary = response.list;

      const dailySummary = sortBy(unsortedDailySummary, "date");

      return {
        dailySummary,
      };
    },
    [startTimestamp, endTimestamp]
  );

  return useFusionAnalyticsAPI<SessionsData>({
    initialValue,
    handleRequest,
  });
};
