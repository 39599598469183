/* tslint:disable */
/* eslint-disable */
/**
 * Melco ControlCenter APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ControlcenterMelcoModelsCreateSupplier,
    ControlcenterMelcoModelsCreateSupplierFromJSON,
    ControlcenterMelcoModelsCreateSupplierToJSON,
    ControlcenterMelcoModelsCreateSupplierProduct,
    ControlcenterMelcoModelsCreateSupplierProductFromJSON,
    ControlcenterMelcoModelsCreateSupplierProductToJSON,
    ControlcenterMelcoModelsError,
    ControlcenterMelcoModelsErrorFromJSON,
    ControlcenterMelcoModelsErrorToJSON,
    ControlcenterMelcoModelsGenericList1MelcoModelsSupplier,
    ControlcenterMelcoModelsGenericList1MelcoModelsSupplierFromJSON,
    ControlcenterMelcoModelsGenericList1MelcoModelsSupplierToJSON,
    ControlcenterMelcoModelsGenericList1MelcoModelsSupplierProduct,
    ControlcenterMelcoModelsGenericList1MelcoModelsSupplierProductFromJSON,
    ControlcenterMelcoModelsGenericList1MelcoModelsSupplierProductToJSON,
    ControlcenterMelcoModelsSupplier,
    ControlcenterMelcoModelsSupplierFromJSON,
    ControlcenterMelcoModelsSupplierToJSON,
    ControlcenterMelcoModelsSupplierProduct,
    ControlcenterMelcoModelsSupplierProductFromJSON,
    ControlcenterMelcoModelsSupplierProductToJSON,
    ControlcenterMelcoModelsUpdateSupplier,
    ControlcenterMelcoModelsUpdateSupplierFromJSON,
    ControlcenterMelcoModelsUpdateSupplierToJSON,
    ControlcenterMelcoModelsUpdateSupplierProduct,
    ControlcenterMelcoModelsUpdateSupplierProductFromJSON,
    ControlcenterMelcoModelsUpdateSupplierProductToJSON,
} from '../models';

export interface SuppliersApiSupplierDeleteRequest {
    id: string;
}

export interface SuppliersApiSupplierDraftCreateRequest {
    controlcenterMelcoModelsCreateSupplier?: ControlcenterMelcoModelsCreateSupplier;
}

export interface SuppliersApiSupplierDraftUpdateRequest {
    id: string;
    controlcenterMelcoModelsUpdateSupplier?: ControlcenterMelcoModelsUpdateSupplier;
}

export interface SuppliersApiSupplierDuplicateDraftRequest {
    id: string;
}

export interface SuppliersApiSupplierGetRequest {
    id: string;
    publishState?: string;
}

export interface SuppliersApiSupplierProductDeleteRequest {
    id: string;
}

export interface SuppliersApiSupplierProductDraftCreateRequest {
    controlcenterMelcoModelsCreateSupplierProduct?: ControlcenterMelcoModelsCreateSupplierProduct;
}

export interface SuppliersApiSupplierProductDraftUpdateRequest {
    id: string;
    controlcenterMelcoModelsUpdateSupplierProduct?: ControlcenterMelcoModelsUpdateSupplierProduct;
}

export interface SuppliersApiSupplierProductDuplicateDraftRequest {
    id: string;
}

export interface SuppliersApiSupplierProductPublishRequest {
    id: string;
}

export interface SuppliersApiSupplierProductRevertRequest {
    id: string;
}

export interface SuppliersApiSupplierProductsGetRequest {
    id: string;
    publishState?: string;
}

export interface SuppliersApiSupplierProductsSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    name?: string;
    supplierId?: string;
    supplierName?: string;
    publishState?: string;
    supplierType?: string;
}

export interface SuppliersApiSupplierPublishRequest {
    id: string;
}

export interface SuppliersApiSupplierRevertRequest {
    id: string;
}

export interface SuppliersApiSupplierSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    name?: string;
    publishState?: string;
    supplierType?: string;
}

/**
 * 
 */
export class SuppliersApi extends runtime.BaseAPI {

    /**
     */
    async supplierDeleteRaw(requestParameters: SuppliersApiSupplierDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling supplierDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async supplierDelete(requestParameters: SuppliersApiSupplierDeleteRequest): Promise<void> {
        await this.supplierDeleteRaw(requestParameters);
    }

    /**
     */
    async supplierDraftCreateRaw(requestParameters: SuppliersApiSupplierDraftCreateRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsSupplier>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/suppliers/draft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlcenterMelcoModelsCreateSupplierToJSON(requestParameters.controlcenterMelcoModelsCreateSupplier),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsSupplierFromJSON(jsonValue));
    }

    /**
     */
    async supplierDraftCreate(requestParameters: SuppliersApiSupplierDraftCreateRequest): Promise<ControlcenterMelcoModelsSupplier> {
        const response = await this.supplierDraftCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async supplierDraftUpdateRaw(requestParameters: SuppliersApiSupplierDraftUpdateRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsSupplier>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling supplierDraftUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/suppliers/{Id}/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ControlcenterMelcoModelsUpdateSupplierToJSON(requestParameters.controlcenterMelcoModelsUpdateSupplier),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsSupplierFromJSON(jsonValue));
    }

    /**
     */
    async supplierDraftUpdate(requestParameters: SuppliersApiSupplierDraftUpdateRequest): Promise<ControlcenterMelcoModelsSupplier> {
        const response = await this.supplierDraftUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async supplierDuplicateDraftRaw(requestParameters: SuppliersApiSupplierDuplicateDraftRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsSupplier>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling supplierDuplicateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/{Id}/duplicate/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsSupplierFromJSON(jsonValue));
    }

    /**
     */
    async supplierDuplicateDraft(requestParameters: SuppliersApiSupplierDuplicateDraftRequest): Promise<ControlcenterMelcoModelsSupplier> {
        const response = await this.supplierDuplicateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async supplierGetRaw(requestParameters: SuppliersApiSupplierGetRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsSupplier>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling supplierGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsSupplierFromJSON(jsonValue));
    }

    /**
     */
    async supplierGet(requestParameters: SuppliersApiSupplierGetRequest): Promise<ControlcenterMelcoModelsSupplier> {
        const response = await this.supplierGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async supplierProductDeleteRaw(requestParameters: SuppliersApiSupplierProductDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling supplierProductDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/products/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async supplierProductDelete(requestParameters: SuppliersApiSupplierProductDeleteRequest): Promise<void> {
        await this.supplierProductDeleteRaw(requestParameters);
    }

    /**
     */
    async supplierProductDraftCreateRaw(requestParameters: SuppliersApiSupplierProductDraftCreateRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsSupplierProduct>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/suppliers/products/draft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlcenterMelcoModelsCreateSupplierProductToJSON(requestParameters.controlcenterMelcoModelsCreateSupplierProduct),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsSupplierProductFromJSON(jsonValue));
    }

    /**
     */
    async supplierProductDraftCreate(requestParameters: SuppliersApiSupplierProductDraftCreateRequest): Promise<ControlcenterMelcoModelsSupplierProduct> {
        const response = await this.supplierProductDraftCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async supplierProductDraftUpdateRaw(requestParameters: SuppliersApiSupplierProductDraftUpdateRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsSupplierProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling supplierProductDraftUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/suppliers/products/{Id}/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ControlcenterMelcoModelsUpdateSupplierProductToJSON(requestParameters.controlcenterMelcoModelsUpdateSupplierProduct),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsSupplierProductFromJSON(jsonValue));
    }

    /**
     */
    async supplierProductDraftUpdate(requestParameters: SuppliersApiSupplierProductDraftUpdateRequest): Promise<ControlcenterMelcoModelsSupplierProduct> {
        const response = await this.supplierProductDraftUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async supplierProductDuplicateDraftRaw(requestParameters: SuppliersApiSupplierProductDuplicateDraftRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsSupplierProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling supplierProductDuplicateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/products/{Id}/duplicate/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsSupplierProductFromJSON(jsonValue));
    }

    /**
     */
    async supplierProductDuplicateDraft(requestParameters: SuppliersApiSupplierProductDuplicateDraftRequest): Promise<ControlcenterMelcoModelsSupplierProduct> {
        const response = await this.supplierProductDuplicateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async supplierProductPublishRaw(requestParameters: SuppliersApiSupplierProductPublishRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsSupplierProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling supplierProductPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/products/{Id}/publish`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsSupplierProductFromJSON(jsonValue));
    }

    /**
     */
    async supplierProductPublish(requestParameters: SuppliersApiSupplierProductPublishRequest): Promise<ControlcenterMelcoModelsSupplierProduct> {
        const response = await this.supplierProductPublishRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async supplierProductRevertRaw(requestParameters: SuppliersApiSupplierProductRevertRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling supplierProductRevert.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/products/{Id}/revert`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async supplierProductRevert(requestParameters: SuppliersApiSupplierProductRevertRequest): Promise<void> {
        await this.supplierProductRevertRaw(requestParameters);
    }

    /**
     */
    async supplierProductsGetRaw(requestParameters: SuppliersApiSupplierProductsGetRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsSupplierProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling supplierProductsGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/products/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsSupplierProductFromJSON(jsonValue));
    }

    /**
     */
    async supplierProductsGet(requestParameters: SuppliersApiSupplierProductsGetRequest): Promise<ControlcenterMelcoModelsSupplierProduct> {
        const response = await this.supplierProductsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async supplierProductsSearchRaw(requestParameters: SuppliersApiSupplierProductsSearchRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsGenericList1MelcoModelsSupplierProduct>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.supplierId !== undefined) {
            queryParameters['supplier_id'] = requestParameters.supplierId;
        }

        if (requestParameters.supplierName !== undefined) {
            queryParameters['supplier_name'] = requestParameters.supplierName;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.supplierType !== undefined) {
            queryParameters['supplier_type'] = requestParameters.supplierType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsGenericList1MelcoModelsSupplierProductFromJSON(jsonValue));
    }

    /**
     */
    async supplierProductsSearch(requestParameters: SuppliersApiSupplierProductsSearchRequest): Promise<ControlcenterMelcoModelsGenericList1MelcoModelsSupplierProduct> {
        const response = await this.supplierProductsSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async supplierPublishRaw(requestParameters: SuppliersApiSupplierPublishRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsSupplier>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling supplierPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/{Id}/publish`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsSupplierFromJSON(jsonValue));
    }

    /**
     */
    async supplierPublish(requestParameters: SuppliersApiSupplierPublishRequest): Promise<ControlcenterMelcoModelsSupplier> {
        const response = await this.supplierPublishRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async supplierRevertRaw(requestParameters: SuppliersApiSupplierRevertRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling supplierRevert.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/{Id}/revert`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async supplierRevert(requestParameters: SuppliersApiSupplierRevertRequest): Promise<void> {
        await this.supplierRevertRaw(requestParameters);
    }

    /**
     */
    async supplierSearchRaw(requestParameters: SuppliersApiSupplierSearchRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsGenericList1MelcoModelsSupplier>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.supplierType !== undefined) {
            queryParameters['supplier_type'] = requestParameters.supplierType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsGenericList1MelcoModelsSupplierFromJSON(jsonValue));
    }

    /**
     */
    async supplierSearch(requestParameters: SuppliersApiSupplierSearchRequest): Promise<ControlcenterMelcoModelsGenericList1MelcoModelsSupplier> {
        const response = await this.supplierSearchRaw(requestParameters);
        return await response.value();
    }

}
