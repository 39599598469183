/* tslint:disable */
/* eslint-disable */
/**
 * Melco Identity APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IdentityMelcoModelsLocaleGroup
 */
export interface IdentityMelcoModelsLocaleGroup {
    /**
     * 
     * @type {string}
     * @memberof IdentityMelcoModelsLocaleGroup
     */
    name?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof IdentityMelcoModelsLocaleGroup
     */
    strings?: { [key: string]: string; } | null;
}

export function IdentityMelcoModelsLocaleGroupFromJSON(json: any): IdentityMelcoModelsLocaleGroup {
    return IdentityMelcoModelsLocaleGroupFromJSONTyped(json, false);
}

export function IdentityMelcoModelsLocaleGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentityMelcoModelsLocaleGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'strings': !exists(json, 'strings') ? undefined : json['strings'],
    };
}

export function IdentityMelcoModelsLocaleGroupToJSON(value?: IdentityMelcoModelsLocaleGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'strings': value.strings,
    };
}


