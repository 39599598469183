/* tslint:disable */
/* eslint-disable */
/**
 * Melco Identity APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IdentityMelcoAnalyticsModelsWidgetListItem,
    IdentityMelcoAnalyticsModelsWidgetListItemFromJSON,
    IdentityMelcoAnalyticsModelsWidgetListItemFromJSONTyped,
    IdentityMelcoAnalyticsModelsWidgetListItemToJSON,
    IdentityMelcoModelsCustomWarning,
    IdentityMelcoModelsCustomWarningFromJSON,
    IdentityMelcoModelsCustomWarningFromJSONTyped,
    IdentityMelcoModelsCustomWarningToJSON,
} from './';

/**
 * 
 * @export
 * @interface IdentityMelcoModelsGenericList1MelcoAnalyticsModelsWidgetListItem
 */
export interface IdentityMelcoModelsGenericList1MelcoAnalyticsModelsWidgetListItem {
    /**
     * 
     * @type {Array<IdentityMelcoAnalyticsModelsWidgetListItem>}
     * @memberof IdentityMelcoModelsGenericList1MelcoAnalyticsModelsWidgetListItem
     */
    list: Array<IdentityMelcoAnalyticsModelsWidgetListItem>;
    /**
     * 
     * @type {Array<IdentityMelcoModelsCustomWarning>}
     * @memberof IdentityMelcoModelsGenericList1MelcoAnalyticsModelsWidgetListItem
     */
    warning_list?: Array<IdentityMelcoModelsCustomWarning> | null;
    /**
     * 
     * @type {number}
     * @memberof IdentityMelcoModelsGenericList1MelcoAnalyticsModelsWidgetListItem
     */
    total: number;
}

export function IdentityMelcoModelsGenericList1MelcoAnalyticsModelsWidgetListItemFromJSON(json: any): IdentityMelcoModelsGenericList1MelcoAnalyticsModelsWidgetListItem {
    return IdentityMelcoModelsGenericList1MelcoAnalyticsModelsWidgetListItemFromJSONTyped(json, false);
}

export function IdentityMelcoModelsGenericList1MelcoAnalyticsModelsWidgetListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentityMelcoModelsGenericList1MelcoAnalyticsModelsWidgetListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(IdentityMelcoAnalyticsModelsWidgetListItemFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(IdentityMelcoModelsCustomWarningFromJSON)),
        'total': json['total'],
    };
}

export function IdentityMelcoModelsGenericList1MelcoAnalyticsModelsWidgetListItemToJSON(value?: IdentityMelcoModelsGenericList1MelcoAnalyticsModelsWidgetListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value.list as Array<any>).map(IdentityMelcoAnalyticsModelsWidgetListItemToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(IdentityMelcoModelsCustomWarningToJSON)),
        'total': value.total,
    };
}


