/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoModelsBoolResponse,
    MelcoModelsBoolResponseFromJSON,
    MelcoModelsBoolResponseToJSON,
    MelcoModelsError,
    MelcoModelsErrorFromJSON,
    MelcoModelsErrorToJSON,
    MelcoModelsGenericList1MelcoModelsSerialNumberHistory,
    MelcoModelsGenericList1MelcoModelsSerialNumberHistoryFromJSON,
    MelcoModelsGenericList1MelcoModelsSerialNumberHistoryToJSON,
    MelcoModelsTransferSerialNumber,
    MelcoModelsTransferSerialNumberFromJSON,
    MelcoModelsTransferSerialNumberToJSON,
    MelcoModelsUserProductPermissions,
    MelcoModelsUserProductPermissionsFromJSON,
    MelcoModelsUserProductPermissionsToJSON,
    MelcoModelsUserSerialNumber,
    MelcoModelsUserSerialNumberFromJSON,
    MelcoModelsUserSerialNumberToJSON,
} from '../models';

export interface ControlCenterManageApiGetBySerialNumberRequest {
    serialNumber: string;
}

export interface ControlCenterManageApiGetHistoryBySerialNumberRequest {
    serialNumber: string;
}

export interface ControlCenterManageApiResendEmailSerialNumberRequest {
    serialNumber: string;
}

export interface ControlCenterManageApiTransferSerialNumberRequest {
    melcoModelsTransferSerialNumber?: MelcoModelsTransferSerialNumber;
}

export interface ControlCenterManageApiUserAccountEditPermissionRequest {
    userId: string;
    melcoModelsUserProductPermissions?: MelcoModelsUserProductPermissions;
}

export interface ControlCenterManageApiUserAccountGetPermissionRequest {
    userId: string;
}

/**
 * 
 */
export class ControlCenterManageApi extends runtime.BaseAPI {

    /**
     * get serial number
     */
    async getBySerialNumberRaw(requestParameters: ControlCenterManageApiGetBySerialNumberRequest): Promise<runtime.ApiResponse<MelcoModelsUserSerialNumber>> {
        if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
            throw new runtime.RequiredError('serialNumber','Required parameter requestParameters.serialNumber was null or undefined when calling getBySerialNumber.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/products/licenses/{serial_number}`.replace(`{${"serial_number"}}`, encodeURIComponent(String(requestParameters.serialNumber))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserSerialNumberFromJSON(jsonValue));
    }

    /**
     * get serial number
     */
    async getBySerialNumber(requestParameters: ControlCenterManageApiGetBySerialNumberRequest): Promise<MelcoModelsUserSerialNumber> {
        const response = await this.getBySerialNumberRaw(requestParameters);
        return await response.value();
    }

    /**
     * get history by serial number
     */
    async getHistoryBySerialNumberRaw(requestParameters: ControlCenterManageApiGetHistoryBySerialNumberRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsSerialNumberHistory>> {
        if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
            throw new runtime.RequiredError('serialNumber','Required parameter requestParameters.serialNumber was null or undefined when calling getHistoryBySerialNumber.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/products/licenses/{serial_number}/history`.replace(`{${"serial_number"}}`, encodeURIComponent(String(requestParameters.serialNumber))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsSerialNumberHistoryFromJSON(jsonValue));
    }

    /**
     * get history by serial number
     */
    async getHistoryBySerialNumber(requestParameters: ControlCenterManageApiGetHistoryBySerialNumberRequest): Promise<MelcoModelsGenericList1MelcoModelsSerialNumberHistory> {
        const response = await this.getHistoryBySerialNumberRaw(requestParameters);
        return await response.value();
    }

    /**
     * resend email mapped to serial number
     */
    async resendEmailSerialNumberRaw(requestParameters: ControlCenterManageApiResendEmailSerialNumberRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
            throw new runtime.RequiredError('serialNumber','Required parameter requestParameters.serialNumber was null or undefined when calling resendEmailSerialNumber.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/products/licenses/{serial_number}/resend_email`.replace(`{${"serial_number"}}`, encodeURIComponent(String(requestParameters.serialNumber))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * resend email mapped to serial number
     */
    async resendEmailSerialNumber(requestParameters: ControlCenterManageApiResendEmailSerialNumberRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.resendEmailSerialNumberRaw(requestParameters);
        return await response.value();
    }

    /**
     * transfer serial number
     */
    async transferSerialNumberRaw(requestParameters: ControlCenterManageApiTransferSerialNumberRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/products/licenses/transfer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsTransferSerialNumberToJSON(requestParameters.melcoModelsTransferSerialNumber),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * transfer serial number
     */
    async transferSerialNumber(requestParameters: ControlCenterManageApiTransferSerialNumberRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.transferSerialNumberRaw(requestParameters);
        return await response.value();
    }

    /**
     * edit user permissions for control center
     */
    async userAccountEditPermissionRaw(requestParameters: ControlCenterManageApiUserAccountEditPermissionRequest): Promise<runtime.ApiResponse<MelcoModelsUserProductPermissions>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userAccountEditPermission.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/account/{user_id}/manage_access`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUserProductPermissionsToJSON(requestParameters.melcoModelsUserProductPermissions),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserProductPermissionsFromJSON(jsonValue));
    }

    /**
     * edit user permissions for control center
     */
    async userAccountEditPermission(requestParameters: ControlCenterManageApiUserAccountEditPermissionRequest): Promise<MelcoModelsUserProductPermissions> {
        const response = await this.userAccountEditPermissionRaw(requestParameters);
        return await response.value();
    }

    /**
     * get user product permissions from control center
     */
    async userAccountGetPermissionRaw(requestParameters: ControlCenterManageApiUserAccountGetPermissionRequest): Promise<runtime.ApiResponse<MelcoModelsUserProductPermissions>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userAccountGetPermission.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/account/{user_id}/manage_access`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserProductPermissionsFromJSON(jsonValue));
    }

    /**
     * get user product permissions from control center
     */
    async userAccountGetPermission(requestParameters: ControlCenterManageApiUserAccountGetPermissionRequest): Promise<MelcoModelsUserProductPermissions> {
        const response = await this.userAccountGetPermissionRaw(requestParameters);
        return await response.value();
    }

}
