/* tslint:disable */
/* eslint-disable */
/**
 * Melco ControlCenter APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlcenterMelcoModelsLegacySerialNumber
 */
export interface ControlcenterMelcoModelsLegacySerialNumber {
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsLegacySerialNumber
     */
    serial_number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsLegacySerialNumber
     */
    product_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsLegacySerialNumber
     */
    product_name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlcenterMelcoModelsLegacySerialNumber
     */
    product_version?: number;
    /**
     * 
     * @type {Date}
     * @memberof ControlcenterMelcoModelsLegacySerialNumber
     */
    created_date?: Date;
}

export function ControlcenterMelcoModelsLegacySerialNumberFromJSON(json: any): ControlcenterMelcoModelsLegacySerialNumber {
    return ControlcenterMelcoModelsLegacySerialNumberFromJSONTyped(json, false);
}

export function ControlcenterMelcoModelsLegacySerialNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlcenterMelcoModelsLegacySerialNumber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serial_number': !exists(json, 'serial_number') ? undefined : json['serial_number'],
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
        'product_name': !exists(json, 'product_name') ? undefined : json['product_name'],
        'product_version': !exists(json, 'product_version') ? undefined : json['product_version'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
    };
}

export function ControlcenterMelcoModelsLegacySerialNumberToJSON(value?: ControlcenterMelcoModelsLegacySerialNumber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serial_number': value.serial_number,
        'product_code': value.product_code,
        'product_name': value.product_name,
        'product_version': value.product_version,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
    };
}


