/* tslint:disable */
/* eslint-disable */
/**
 * Melco ControlCenter APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ControlcenterMelcoControlCenterModelsForwardLinkRequest,
    ControlcenterMelcoControlCenterModelsForwardLinkRequestFromJSON,
    ControlcenterMelcoControlCenterModelsForwardLinkRequestToJSON,
    ControlcenterMelcoControlCenterModelsForwardLinkResponse,
    ControlcenterMelcoControlCenterModelsForwardLinkResponseFromJSON,
    ControlcenterMelcoControlCenterModelsForwardLinkResponseToJSON,
    ControlcenterMelcoModelsError,
    ControlcenterMelcoModelsErrorFromJSON,
    ControlcenterMelcoModelsErrorToJSON,
    ControlcenterMelcoModelsGenericList1MelcoControlCenterModelsForwardLinkListItem,
    ControlcenterMelcoModelsGenericList1MelcoControlCenterModelsForwardLinkListItemFromJSON,
    ControlcenterMelcoModelsGenericList1MelcoControlCenterModelsForwardLinkListItemToJSON,
} from '../models';

export interface UrlForwardsApiUrlForwardsCreateRequest {
    controlcenterMelcoControlCenterModelsForwardLinkRequest?: ControlcenterMelcoControlCenterModelsForwardLinkRequest;
}

export interface UrlForwardsApiUrlForwardsDeleteRequest {
    id: string;
}

export interface UrlForwardsApiUrlForwardsEditRequest {
    id: string;
    controlcenterMelcoControlCenterModelsForwardLinkRequest?: ControlcenterMelcoControlCenterModelsForwardLinkRequest;
}

export interface UrlForwardsApiUrlForwardsGetRequest {
    id: string;
}

export interface UrlForwardsApiUrlForwardsGetByKeyRequest {
    key: string;
}

export interface UrlForwardsApiUrlForwardsGetListRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
}

/**
 * 
 */
export class UrlForwardsApi extends runtime.BaseAPI {

    /**
     */
    async urlForwardsCreateRaw(requestParameters: UrlForwardsApiUrlForwardsCreateRequest): Promise<runtime.ApiResponse<ControlcenterMelcoControlCenterModelsForwardLinkResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/url/forwards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlcenterMelcoControlCenterModelsForwardLinkRequestToJSON(requestParameters.controlcenterMelcoControlCenterModelsForwardLinkRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoControlCenterModelsForwardLinkResponseFromJSON(jsonValue));
    }

    /**
     */
    async urlForwardsCreate(requestParameters: UrlForwardsApiUrlForwardsCreateRequest): Promise<ControlcenterMelcoControlCenterModelsForwardLinkResponse> {
        const response = await this.urlForwardsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async urlForwardsDeleteRaw(requestParameters: UrlForwardsApiUrlForwardsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling urlForwardsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/url/forwards/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async urlForwardsDelete(requestParameters: UrlForwardsApiUrlForwardsDeleteRequest): Promise<void> {
        await this.urlForwardsDeleteRaw(requestParameters);
    }

    /**
     */
    async urlForwardsEditRaw(requestParameters: UrlForwardsApiUrlForwardsEditRequest): Promise<runtime.ApiResponse<ControlcenterMelcoControlCenterModelsForwardLinkResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling urlForwardsEdit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/url/forwards/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ControlcenterMelcoControlCenterModelsForwardLinkRequestToJSON(requestParameters.controlcenterMelcoControlCenterModelsForwardLinkRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoControlCenterModelsForwardLinkResponseFromJSON(jsonValue));
    }

    /**
     */
    async urlForwardsEdit(requestParameters: UrlForwardsApiUrlForwardsEditRequest): Promise<ControlcenterMelcoControlCenterModelsForwardLinkResponse> {
        const response = await this.urlForwardsEditRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async urlForwardsGetRaw(requestParameters: UrlForwardsApiUrlForwardsGetRequest): Promise<runtime.ApiResponse<ControlcenterMelcoControlCenterModelsForwardLinkResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling urlForwardsGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/url/forwards/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoControlCenterModelsForwardLinkResponseFromJSON(jsonValue));
    }

    /**
     */
    async urlForwardsGet(requestParameters: UrlForwardsApiUrlForwardsGetRequest): Promise<ControlcenterMelcoControlCenterModelsForwardLinkResponse> {
        const response = await this.urlForwardsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async urlForwardsGetByKeyRaw(requestParameters: UrlForwardsApiUrlForwardsGetByKeyRequest): Promise<runtime.ApiResponse<ControlcenterMelcoControlCenterModelsForwardLinkResponse>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling urlForwardsGetByKey.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/url/forwards/key/{Key}`.replace(`{${"Key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoControlCenterModelsForwardLinkResponseFromJSON(jsonValue));
    }

    /**
     */
    async urlForwardsGetByKey(requestParameters: UrlForwardsApiUrlForwardsGetByKeyRequest): Promise<ControlcenterMelcoControlCenterModelsForwardLinkResponse> {
        const response = await this.urlForwardsGetByKeyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async urlForwardsGetListRaw(requestParameters: UrlForwardsApiUrlForwardsGetListRequest): Promise<runtime.ApiResponse<ControlcenterMelcoModelsGenericList1MelcoControlCenterModelsForwardLinkListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/url/forwards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlcenterMelcoModelsGenericList1MelcoControlCenterModelsForwardLinkListItemFromJSON(jsonValue));
    }

    /**
     */
    async urlForwardsGetList(requestParameters: UrlForwardsApiUrlForwardsGetListRequest): Promise<ControlcenterMelcoModelsGenericList1MelcoControlCenterModelsForwardLinkListItem> {
        const response = await this.urlForwardsGetListRaw(requestParameters);
        return await response.value();
    }

}
