/* tslint:disable */
/* eslint-disable */
/**
 * Melco Identity APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IdentityMelcoModelsCountry
 */
export interface IdentityMelcoModelsCountry {
    /**
     * 
     * @type {string}
     * @memberof IdentityMelcoModelsCountry
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityMelcoModelsCountry
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityMelcoModelsCountry
     */
    double_opt_in?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IdentityMelcoModelsCountry
     */
    default_currency_code?: string | null;
}

export function IdentityMelcoModelsCountryFromJSON(json: any): IdentityMelcoModelsCountry {
    return IdentityMelcoModelsCountryFromJSONTyped(json, false);
}

export function IdentityMelcoModelsCountryFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentityMelcoModelsCountry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'double_opt_in': !exists(json, 'double_opt_in') ? undefined : json['double_opt_in'],
        'default_currency_code': !exists(json, 'default_currency_code') ? undefined : json['default_currency_code'],
    };
}

export function IdentityMelcoModelsCountryToJSON(value?: IdentityMelcoModelsCountry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
        'double_opt_in': value.double_opt_in,
        'default_currency_code': value.default_currency_code,
    };
}


