/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FusionMelcoModelsCustomWarning,
    FusionMelcoModelsCustomWarningFromJSON,
    FusionMelcoModelsCustomWarningFromJSONTyped,
    FusionMelcoModelsCustomWarningToJSON,
    FusionMelcoModelsSessionDailySummary,
    FusionMelcoModelsSessionDailySummaryFromJSON,
    FusionMelcoModelsSessionDailySummaryFromJSONTyped,
    FusionMelcoModelsSessionDailySummaryToJSON,
} from './';

/**
 * 
 * @export
 * @interface FusionMelcoModelsGenericList1MelcoModelsSessionDailySummary
 */
export interface FusionMelcoModelsGenericList1MelcoModelsSessionDailySummary {
    /**
     * 
     * @type {Array<FusionMelcoModelsSessionDailySummary>}
     * @memberof FusionMelcoModelsGenericList1MelcoModelsSessionDailySummary
     */
    list: Array<FusionMelcoModelsSessionDailySummary>;
    /**
     * 
     * @type {Array<FusionMelcoModelsCustomWarning>}
     * @memberof FusionMelcoModelsGenericList1MelcoModelsSessionDailySummary
     */
    warning_list?: Array<FusionMelcoModelsCustomWarning> | null;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoModelsGenericList1MelcoModelsSessionDailySummary
     */
    total: number;
}

export function FusionMelcoModelsGenericList1MelcoModelsSessionDailySummaryFromJSON(json: any): FusionMelcoModelsGenericList1MelcoModelsSessionDailySummary {
    return FusionMelcoModelsGenericList1MelcoModelsSessionDailySummaryFromJSONTyped(json, false);
}

export function FusionMelcoModelsGenericList1MelcoModelsSessionDailySummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): FusionMelcoModelsGenericList1MelcoModelsSessionDailySummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(FusionMelcoModelsSessionDailySummaryFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(FusionMelcoModelsCustomWarningFromJSON)),
        'total': json['total'],
    };
}

export function FusionMelcoModelsGenericList1MelcoModelsSessionDailySummaryToJSON(value?: FusionMelcoModelsGenericList1MelcoModelsSessionDailySummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value.list as Array<any>).map(FusionMelcoModelsSessionDailySummaryToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(FusionMelcoModelsCustomWarningToJSON)),
        'total': value.total,
    };
}


