/* tslint:disable */
/* eslint-disable */
/**
 * Melco APIService APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiserviceMelcoModelsCustomWarning,
    ApiserviceMelcoModelsCustomWarningFromJSON,
    ApiserviceMelcoModelsCustomWarningFromJSONTyped,
    ApiserviceMelcoModelsCustomWarningToJSON,
    ApiserviceMelcoModelsPreview,
    ApiserviceMelcoModelsPreviewFromJSON,
    ApiserviceMelcoModelsPreviewFromJSONTyped,
    ApiserviceMelcoModelsPreviewToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiserviceMelcoModelsUserAlphabet
 */
export interface ApiserviceMelcoModelsUserAlphabet {
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsUserAlphabet
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsUserAlphabet
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsUserAlphabet
     */
    style?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiserviceMelcoModelsUserAlphabet
     */
    service_id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsUserAlphabet
     */
    service_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsUserAlphabet
     */
    recommended_text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsUserAlphabet
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsUserAlphabet
     */
    signature: string;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsUserAlphabet
     */
    file_name: string;
    /**
     * 
     * @type {number}
     * @memberof ApiserviceMelcoModelsUserAlphabet
     */
    file_size: number;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsUserAlphabet
     */
    original_file_name?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ApiserviceMelcoModelsUserAlphabet
     */
    created_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApiserviceMelcoModelsUserAlphabet
     */
    publish_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ApiserviceMelcoModelsUserAlphabet
     */
    updated_date?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ApiserviceMelcoModelsUserAlphabet
     */
    publish_state?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsUserAlphabet
     */
    rfm_url?: string | null;
    /**
     * 
     * @type {Array<ApiserviceMelcoModelsCustomWarning>}
     * @memberof ApiserviceMelcoModelsUserAlphabet
     */
    warning_list?: Array<ApiserviceMelcoModelsCustomWarning> | null;
    /**
     * 
     * @type {Array<ApiserviceMelcoModelsPreview>}
     * @memberof ApiserviceMelcoModelsUserAlphabet
     */
    previews?: Array<ApiserviceMelcoModelsPreview> | null;
}

export function ApiserviceMelcoModelsUserAlphabetFromJSON(json: any): ApiserviceMelcoModelsUserAlphabet {
    return ApiserviceMelcoModelsUserAlphabetFromJSONTyped(json, false);
}

export function ApiserviceMelcoModelsUserAlphabetFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiserviceMelcoModelsUserAlphabet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'style': !exists(json, 'style') ? undefined : json['style'],
        'service_id': !exists(json, 'service_id') ? undefined : json['service_id'],
        'service_name': !exists(json, 'service_name') ? undefined : json['service_name'],
        'recommended_text': !exists(json, 'recommended_text') ? undefined : json['recommended_text'],
        'type': json['type'],
        'signature': json['signature'],
        'file_name': json['file_name'],
        'file_size': json['file_size'],
        'original_file_name': !exists(json, 'original_file_name') ? undefined : json['original_file_name'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'publish_date': !exists(json, 'publish_date') ? undefined : (json['publish_date'] === null ? null : new Date(json['publish_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
        'publish_state': !exists(json, 'publish_state') ? undefined : json['publish_state'],
        'rfm_url': !exists(json, 'rfm_url') ? undefined : json['rfm_url'],
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(ApiserviceMelcoModelsCustomWarningFromJSON)),
        'previews': !exists(json, 'previews') ? undefined : (json['previews'] === null ? null : (json['previews'] as Array<any>).map(ApiserviceMelcoModelsPreviewFromJSON)),
    };
}

export function ApiserviceMelcoModelsUserAlphabetToJSON(value?: ApiserviceMelcoModelsUserAlphabet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'style': value.style,
        'service_id': value.service_id,
        'service_name': value.service_name,
        'recommended_text': value.recommended_text,
        'type': value.type,
        'signature': value.signature,
        'file_name': value.file_name,
        'file_size': value.file_size,
        'original_file_name': value.original_file_name,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'publish_date': value.publish_date === undefined ? undefined : (value.publish_date === null ? null : value.publish_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
        'publish_state': value.publish_state,
        'rfm_url': value.rfm_url,
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(ApiserviceMelcoModelsCustomWarningToJSON)),
        'previews': value.previews === undefined ? undefined : (value.previews === null ? null : (value.previews as Array<any>).map(ApiserviceMelcoModelsPreviewToJSON)),
    };
}


