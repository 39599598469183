/* tslint:disable */
/* eslint-disable */
/**
 * Melco ControlCenter APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlcenterMelcoModelsLocaleStringListItem
 */
export interface ControlcenterMelcoModelsLocaleStringListItem {
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsLocaleStringListItem
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsLocaleStringListItem
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsLocaleStringListItem
     */
    source?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsLocaleStringListItem
     */
    module?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsLocaleStringListItem
     */
    text?: string | null;
}

export function ControlcenterMelcoModelsLocaleStringListItemFromJSON(json: any): ControlcenterMelcoModelsLocaleStringListItem {
    return ControlcenterMelcoModelsLocaleStringListItemFromJSONTyped(json, false);
}

export function ControlcenterMelcoModelsLocaleStringListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlcenterMelcoModelsLocaleStringListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'module': !exists(json, 'module') ? undefined : json['module'],
        'text': !exists(json, 'text') ? undefined : json['text'],
    };
}

export function ControlcenterMelcoModelsLocaleStringListItemToJSON(value?: ControlcenterMelcoModelsLocaleStringListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'key': value.key,
        'source': value.source,
        'module': value.module,
        'text': value.text,
    };
}


