/* tslint:disable */
/* eslint-disable */
/**
 * Melco Identity APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IdentityMelcoModelsCustomWarning,
    IdentityMelcoModelsCustomWarningFromJSON,
    IdentityMelcoModelsCustomWarningFromJSONTyped,
    IdentityMelcoModelsCustomWarningToJSON,
    IdentityMelcoModelsReferralListItem,
    IdentityMelcoModelsReferralListItemFromJSON,
    IdentityMelcoModelsReferralListItemFromJSONTyped,
    IdentityMelcoModelsReferralListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface IdentityMelcoModelsGenericList1MelcoModelsReferralListItem
 */
export interface IdentityMelcoModelsGenericList1MelcoModelsReferralListItem {
    /**
     * 
     * @type {Array<IdentityMelcoModelsReferralListItem>}
     * @memberof IdentityMelcoModelsGenericList1MelcoModelsReferralListItem
     */
    list: Array<IdentityMelcoModelsReferralListItem>;
    /**
     * 
     * @type {Array<IdentityMelcoModelsCustomWarning>}
     * @memberof IdentityMelcoModelsGenericList1MelcoModelsReferralListItem
     */
    warning_list?: Array<IdentityMelcoModelsCustomWarning> | null;
    /**
     * 
     * @type {number}
     * @memberof IdentityMelcoModelsGenericList1MelcoModelsReferralListItem
     */
    total: number;
}

export function IdentityMelcoModelsGenericList1MelcoModelsReferralListItemFromJSON(json: any): IdentityMelcoModelsGenericList1MelcoModelsReferralListItem {
    return IdentityMelcoModelsGenericList1MelcoModelsReferralListItemFromJSONTyped(json, false);
}

export function IdentityMelcoModelsGenericList1MelcoModelsReferralListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentityMelcoModelsGenericList1MelcoModelsReferralListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(IdentityMelcoModelsReferralListItemFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(IdentityMelcoModelsCustomWarningFromJSON)),
        'total': json['total'],
    };
}

export function IdentityMelcoModelsGenericList1MelcoModelsReferralListItemToJSON(value?: IdentityMelcoModelsGenericList1MelcoModelsReferralListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value.list as Array<any>).map(IdentityMelcoModelsReferralListItemToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(IdentityMelcoModelsCustomWarningToJSON)),
        'total': value.total,
    };
}


