/* tslint:disable */
/* eslint-disable */
/**
 * Melco APIService APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiserviceMelcoModelsPreview,
    ApiserviceMelcoModelsPreviewFromJSON,
    ApiserviceMelcoModelsPreviewFromJSONTyped,
    ApiserviceMelcoModelsPreviewToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiserviceMelcoModelsDesignListItem
 */
export interface ApiserviceMelcoModelsDesignListItem {
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    file_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    signature?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    stitches?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    colors?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    trims?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    color_changes?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    unit?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    file_size?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    file_type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    image_resolution?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    original_file_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    file_extension?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    rfm_url?: string | null;
    /**
     * 
     * @type {Array<ApiserviceMelcoModelsPreview>}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    previews?: Array<ApiserviceMelcoModelsPreview> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    publish_state?: number;
    /**
     * 
     * @type {Date}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    created_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    publish_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ApiserviceMelcoModelsDesignListItem
     */
    updated_date?: Date | null;
}

export function ApiserviceMelcoModelsDesignListItemFromJSON(json: any): ApiserviceMelcoModelsDesignListItem {
    return ApiserviceMelcoModelsDesignListItemFromJSONTyped(json, false);
}

export function ApiserviceMelcoModelsDesignListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiserviceMelcoModelsDesignListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'file_name': !exists(json, 'file_name') ? undefined : json['file_name'],
        'signature': !exists(json, 'signature') ? undefined : json['signature'],
        'stitches': !exists(json, 'stitches') ? undefined : json['stitches'],
        'colors': !exists(json, 'colors') ? undefined : json['colors'],
        'trims': !exists(json, 'trims') ? undefined : json['trims'],
        'color_changes': !exists(json, 'color_changes') ? undefined : json['color_changes'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'file_size': !exists(json, 'file_size') ? undefined : json['file_size'],
        'file_type': !exists(json, 'file_type') ? undefined : json['file_type'],
        'image_resolution': !exists(json, 'image_resolution') ? undefined : json['image_resolution'],
        'original_file_name': !exists(json, 'original_file_name') ? undefined : json['original_file_name'],
        'file_extension': !exists(json, 'file_extension') ? undefined : json['file_extension'],
        'rfm_url': !exists(json, 'rfm_url') ? undefined : json['rfm_url'],
        'previews': !exists(json, 'previews') ? undefined : (json['previews'] === null ? null : (json['previews'] as Array<any>).map(ApiserviceMelcoModelsPreviewFromJSON)),
        'publish_state': !exists(json, 'publish_state') ? undefined : json['publish_state'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'publish_date': !exists(json, 'publish_date') ? undefined : (json['publish_date'] === null ? null : new Date(json['publish_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
    };
}

export function ApiserviceMelcoModelsDesignListItemToJSON(value?: ApiserviceMelcoModelsDesignListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'file_name': value.file_name,
        'signature': value.signature,
        'stitches': value.stitches,
        'colors': value.colors,
        'trims': value.trims,
        'color_changes': value.color_changes,
        'unit': value.unit,
        'height': value.height,
        'width': value.width,
        'file_size': value.file_size,
        'file_type': value.file_type,
        'image_resolution': value.image_resolution,
        'original_file_name': value.original_file_name,
        'file_extension': value.file_extension,
        'rfm_url': value.rfm_url,
        'previews': value.previews === undefined ? undefined : (value.previews === null ? null : (value.previews as Array<any>).map(ApiserviceMelcoModelsPreviewToJSON)),
        'publish_state': value.publish_state,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'publish_date': value.publish_date === undefined ? undefined : (value.publish_date === null ? null : value.publish_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
    };
}


