/* tslint:disable */
/* eslint-disable */
/**
 * Melco ControlCenter APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlcenterMelcoModelsApiKey
 */
export interface ControlcenterMelcoModelsApiKey {
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsApiKey
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsApiKey
     */
    api_key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsApiKey
     */
    name?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ControlcenterMelcoModelsApiKey
     */
    expire_on?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsApiKey
     */
    product_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsApiKey
     */
    product_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsApiKey
     */
    scope?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ControlcenterMelcoModelsApiKey
     */
    created_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof ControlcenterMelcoModelsApiKey
     */
    user_id?: string | null;
}

export function ControlcenterMelcoModelsApiKeyFromJSON(json: any): ControlcenterMelcoModelsApiKey {
    return ControlcenterMelcoModelsApiKeyFromJSONTyped(json, false);
}

export function ControlcenterMelcoModelsApiKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlcenterMelcoModelsApiKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'api_key': !exists(json, 'api_key') ? undefined : json['api_key'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'expire_on': !exists(json, 'expire_on') ? undefined : (json['expire_on'] === null ? null : new Date(json['expire_on'])),
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
        'product_name': !exists(json, 'product_name') ? undefined : json['product_name'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
    };
}

export function ControlcenterMelcoModelsApiKeyToJSON(value?: ControlcenterMelcoModelsApiKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'api_key': value.api_key,
        'name': value.name,
        'expire_on': value.expire_on === undefined ? undefined : (value.expire_on === null ? null : value.expire_on.toISOString()),
        'product_code': value.product_code,
        'product_name': value.product_name,
        'scope': value.scope,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'user_id': value.user_id,
    };
}


