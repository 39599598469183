import {
  ApiServiceSettingsApi,
  useApiServiceSettingsAPI,
} from "melco-shared-logic";
import { useCallback } from "react";
import { Settings } from "./useSettings";

const initialValue = {
  settings: undefined,
};

export const useAPIServiceSettings = () => {
  const handleRequest = useCallback(async (api: ApiServiceSettingsApi) => {
    const settings = await api.settingsGetApiServiceAppSetting();
    return { settings };
  }, []);

  return useApiServiceSettingsAPI<Settings>({
    initialValue,
    handleRequest,
  });
};
