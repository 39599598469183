/* tslint:disable */
/* eslint-disable */
/**
 * Melco Identity APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IdentityMelcoModelsError,
    IdentityMelcoModelsErrorFromJSON,
    IdentityMelcoModelsErrorToJSON,
    IdentityMelcoModelsGenericList1MelcoAnalyticsModelsWidgetListItem,
    IdentityMelcoModelsGenericList1MelcoAnalyticsModelsWidgetListItemFromJSON,
    IdentityMelcoModelsGenericList1MelcoAnalyticsModelsWidgetListItemToJSON,
    IdentityMelcoModelsGenericList1MelcoModelsCountry,
    IdentityMelcoModelsGenericList1MelcoModelsCountryFromJSON,
    IdentityMelcoModelsGenericList1MelcoModelsCountryToJSON,
    IdentityMelcoModelsGenericList1MelcoModelsCountryState,
    IdentityMelcoModelsGenericList1MelcoModelsCountryStateFromJSON,
    IdentityMelcoModelsGenericList1MelcoModelsCountryStateToJSON,
    IdentityMelcoModelsGenericList1MelcoModelsCulture,
    IdentityMelcoModelsGenericList1MelcoModelsCultureFromJSON,
    IdentityMelcoModelsGenericList1MelcoModelsCultureToJSON,
    IdentityMelcoModelsGenericList1MelcoModelsCurrency,
    IdentityMelcoModelsGenericList1MelcoModelsCurrencyFromJSON,
    IdentityMelcoModelsGenericList1MelcoModelsCurrencyToJSON,
    IdentityMelcoModelsGenericList1MelcoModelsGender,
    IdentityMelcoModelsGenericList1MelcoModelsGenderFromJSON,
    IdentityMelcoModelsGenericList1MelcoModelsGenderToJSON,
    IdentityMelcoModelsGenericList1MelcoModelsLocaleGroup,
    IdentityMelcoModelsGenericList1MelcoModelsLocaleGroupFromJSON,
    IdentityMelcoModelsGenericList1MelcoModelsLocaleGroupToJSON,
    IdentityMelcoModelsGenericList1MelcoModelsReferralListItem,
    IdentityMelcoModelsGenericList1MelcoModelsReferralListItemFromJSON,
    IdentityMelcoModelsGenericList1MelcoModelsReferralListItemToJSON,
} from '../models';

export interface DefaultsApiDefaultsGetLocaleStringListRequest {
    filter?: string;
}

export interface DefaultsApiDefaultsGetStateListRequest {
    countryList?: string;
}

export interface DefaultsApiDefaultsSearchCulturesRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
}

/**
 * 
 */
export class DefaultsApi extends runtime.BaseAPI {

    /**
     */
    async defaultsGetCountryListRaw(): Promise<runtime.ApiResponse<IdentityMelcoModelsGenericList1MelcoModelsCountry>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/countries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsGenericList1MelcoModelsCountryFromJSON(jsonValue));
    }

    /**
     */
    async defaultsGetCountryList(): Promise<IdentityMelcoModelsGenericList1MelcoModelsCountry> {
        const response = await this.defaultsGetCountryListRaw();
        return await response.value();
    }

    /**
     */
    async defaultsGetCurrencyListRaw(): Promise<runtime.ApiResponse<IdentityMelcoModelsGenericList1MelcoModelsCurrency>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/currencies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsGenericList1MelcoModelsCurrencyFromJSON(jsonValue));
    }

    /**
     */
    async defaultsGetCurrencyList(): Promise<IdentityMelcoModelsGenericList1MelcoModelsCurrency> {
        const response = await this.defaultsGetCurrencyListRaw();
        return await response.value();
    }

    /**
     */
    async defaultsGetGenderListRaw(): Promise<runtime.ApiResponse<IdentityMelcoModelsGenericList1MelcoModelsGender>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/genders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsGenericList1MelcoModelsGenderFromJSON(jsonValue));
    }

    /**
     */
    async defaultsGetGenderList(): Promise<IdentityMelcoModelsGenericList1MelcoModelsGender> {
        const response = await this.defaultsGetGenderListRaw();
        return await response.value();
    }

    /**
     */
    async defaultsGetLocaleStringListRaw(requestParameters: DefaultsApiDefaultsGetLocaleStringListRequest): Promise<runtime.ApiResponse<IdentityMelcoModelsGenericList1MelcoModelsLocaleGroup>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/localeStrings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsGenericList1MelcoModelsLocaleGroupFromJSON(jsonValue));
    }

    /**
     */
    async defaultsGetLocaleStringList(requestParameters: DefaultsApiDefaultsGetLocaleStringListRequest): Promise<IdentityMelcoModelsGenericList1MelcoModelsLocaleGroup> {
        const response = await this.defaultsGetLocaleStringListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async defaultsGetReferralListRaw(): Promise<runtime.ApiResponse<IdentityMelcoModelsGenericList1MelcoModelsReferralListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/referrals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsGenericList1MelcoModelsReferralListItemFromJSON(jsonValue));
    }

    /**
     */
    async defaultsGetReferralList(): Promise<IdentityMelcoModelsGenericList1MelcoModelsReferralListItem> {
        const response = await this.defaultsGetReferralListRaw();
        return await response.value();
    }

    /**
     */
    async defaultsGetStateListRaw(requestParameters: DefaultsApiDefaultsGetStateListRequest): Promise<runtime.ApiResponse<IdentityMelcoModelsGenericList1MelcoModelsCountryState>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.countryList !== undefined) {
            queryParameters['CountryList'] = requestParameters.countryList;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/countries/states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsGenericList1MelcoModelsCountryStateFromJSON(jsonValue));
    }

    /**
     */
    async defaultsGetStateList(requestParameters: DefaultsApiDefaultsGetStateListRequest): Promise<IdentityMelcoModelsGenericList1MelcoModelsCountryState> {
        const response = await this.defaultsGetStateListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async defaultsGetWizardsRaw(): Promise<runtime.ApiResponse<IdentityMelcoModelsGenericList1MelcoAnalyticsModelsWidgetListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/widgets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsGenericList1MelcoAnalyticsModelsWidgetListItemFromJSON(jsonValue));
    }

    /**
     */
    async defaultsGetWizards(): Promise<IdentityMelcoModelsGenericList1MelcoAnalyticsModelsWidgetListItem> {
        const response = await this.defaultsGetWizardsRaw();
        return await response.value();
    }

    /**
     */
    async defaultsSearchCulturesRaw(requestParameters: DefaultsApiDefaultsSearchCulturesRequest): Promise<runtime.ApiResponse<IdentityMelcoModelsGenericList1MelcoModelsCulture>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/cultures`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityMelcoModelsGenericList1MelcoModelsCultureFromJSON(jsonValue));
    }

    /**
     */
    async defaultsSearchCultures(requestParameters: DefaultsApiDefaultsSearchCulturesRequest): Promise<IdentityMelcoModelsGenericList1MelcoModelsCulture> {
        const response = await this.defaultsSearchCulturesRaw(requestParameters);
        return await response.value();
    }

}
