/* tslint:disable */
/* eslint-disable */
/**
 * Melco Identity APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IdentityMelcoModelsProductScopeListItem
 */
export interface IdentityMelcoModelsProductScopeListItem {
    /**
     * 
     * @type {string}
     * @memberof IdentityMelcoModelsProductScopeListItem
     */
    scope_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityMelcoModelsProductScopeListItem
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityMelcoModelsProductScopeListItem
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityMelcoModelsProductScopeListItem
     */
    product_code?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof IdentityMelcoModelsProductScopeListItem
     */
    created_on?: Date;
}

export function IdentityMelcoModelsProductScopeListItemFromJSON(json: any): IdentityMelcoModelsProductScopeListItem {
    return IdentityMelcoModelsProductScopeListItemFromJSONTyped(json, false);
}

export function IdentityMelcoModelsProductScopeListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentityMelcoModelsProductScopeListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scope_id': !exists(json, 'scope_id') ? undefined : json['scope_id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
        'created_on': !exists(json, 'created_on') ? undefined : (new Date(json['created_on'])),
    };
}

export function IdentityMelcoModelsProductScopeListItemToJSON(value?: IdentityMelcoModelsProductScopeListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scope_id': value.scope_id,
        'code': value.code,
        'name': value.name,
        'product_code': value.product_code,
        'created_on': value.created_on === undefined ? undefined : (value.created_on.toISOString()),
    };
}


